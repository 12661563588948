<section class="section-one">
    <div class="container-fluid cstm-front">
        <iframe class="background-video" src="https://www.youtube.com/embed/1Er2vFty0D8?controls=0&mute=1&autoplay=1&loop=1&playlist=1Er2vFty0D8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <div class="background-video-shaow"></div>
        <div class="row">
            <div class="col-xl-8 col-lg-12 cstm-left">     
                <span class="line-1a">CICLO</span>
                <span class="line-1b">CICLO</span>
                <span class="line-1c">CICLO</span>
                <span class="line-1">FORMATIVO DE GRADO MEDIO</span>
                <span class="line-2">TÉCNICAS DE</span>
                <span class="line-3">DANZA</span>    
                <span class="line-4">URBANA</span>                
            </div>
            <div class="col-xl-4 col-lg-12 cstm-right">
               
                <img class="logo-generalitat" src="/assets/images/logo_generalitat_e1.png">
            </div>
        </div>
    </div>
</section>

<section class="section-two">
    <div class="paper-break">
        <img src="/assets/images/textured-paper_e5.png">
    </div>
    
    <div class="container-fluid paper-front">
        <div class="row">
            <div class="col-md-6 cstm-left">   
                <img src="/assets/images/muestreo_a1.jpg">
            </div>
            <div class="col-md-6 cstm-right">   
                <ul>
                    <li>APRENDE CON PROFESIONALES EXPERIMENTADOS</li>
                    <li>DESCUBRE TODO TU POTENCIAL</li>
                    <li>TAREAS SIEMPRE GUIADAS Y ASISTIDAS</li>
                    <li>INSTALACIONES IDEALES PARA LA ACTIVIDAD</li>
                </ul>
            </div>
        </div>
        
    </div>
</section>


<section class="section-three">
    
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">   
                <div class="mab-decor">
                    <img class="decor-star" src="/assets/images/decors/star_a1.png" />
                    <span class="line-1a">MAB DANCE CENTER</span>
                    <span class="line-1b">MAB DANCE CENTER</span>
                    <span class="line-1c">MAB DANCE CENTER</span>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="section-four">
    <div class="container-fluid cstm-front">
        <iframe class="background-video" src="https://www.youtube.com/embed/Bm_CRwU48pE?controls=0&mute=1&autoplay=1&loop=1&playlist=Bm_CRwU48pE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <div class="background-video-shaow">
            <div class="first-poly"></div>
            <div class="second-poly"></div>
            <div class="third-poly"></div>
        </div>
        <div class="row">
           <div class="col-md-12">
                <p class="primera-escula-text">SERÁ LA PRIMERA ESCUELA DE DANZA EN BARCELONA EN IMPLEMENTAR EL CICLO FORMATIVO DE GRADO MEDIO DE DANZAS URBANAS, UN TÍTULO PROPIO DE LA GENERALITAT DE CATALUNYA.</p>
           </div>
        </div>
        <div class="row">
            <div class="col-lg-6 offset-lg-6">
               <h1 class="more-info"><span>+</span> INFO</h1>
               <div class="info-form">
                <form>
                    <div class="mb-3">
                        <label for="inputName" class="form-label">Nombre</label>
                        <input type="text" class="form-control" id="inputName" name="inputName" [(ngModel)]="formValuesContacto.name">
                    </div>
                    <div class="mb-3">
                        <label for="inputLastname" class="form-label">Apellidos</label>
                        <input type="text" class="form-control" id="inputLastname" name="inputLastname" [(ngModel)]="formValuesContacto.lastname">
                    </div>
                    <div class="mb-3">
                        <label for="inputEmail" class="form-label">Email</label>
                        <input type="email" class="form-control" id="inputEmail" name="inputEmail" [(ngModel)]="formValuesContacto.email">
                    </div>
                    <div class="mb-3">
                        <label for="inputPhone" class="form-label">Teléfono</label>
                        <input type="text" class="form-control" id="inputPhone"  name="inputPhone" [(ngModel)]="formValuesContacto.phone">
                    </div>
                    <div class="mb-3 form-check">
                        <input type="checkbox" class="form-check-input" id="checkboxTerms" name="checkboxTerms" [(ngModel)]="checkContactLegal" value="1">
                        <label class="form-check-label" for="checkboxTerms">He leído y comprendo la Política de Privacidad y los Términos de uso de la página web.</label>
                    </div>
                    <p class="error" *ngIf="errorEmptyInputsContacto">
                        <i class="fas fa-exclamation-triangle"></i> Debes rellenar todos los campos del formulario y aceptar los términos y condiciones.
                    </p>
                    <div class="send-button-container">
                        <button type="submit" class="send-button" (click)="sendFormContact()">Enviar</button>
                    </div>
                </form>
            </div>
            </div>
         </div>
    </div>
</section>


<section class="section-five">
    <div class="container-fluid buttons-container">
        <div class="row">
            <div class="col-md-12">
                <a class="sign-up-btn" href="https://educacio.gencat.cat/ca/tramits/tramits-temes/22807-dansa-urbana">INSCRÍBETE</a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <img class="image-logo" src="/assets/images/logo_mab_white.png">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <p class="text-bottom">Escola Autoritzada pel Departament d&apos;Ensenyament de la Generalitat de Catalunya. Codi de centre: 08078191. Copyright © 2023 Mab Dance Center</p>
            </div>
        </div>
        
    </div>
</section>


    
    <div class="success-contact" *ngIf="this.showSuccess">
        <div class="success-contact-message" (click)="closeSuccessMessage()">
            Se ha enviado tu solicitud, nos pondremos en contacto lo antes posible.
        </div>
    </div>

    <a class="whatsapp" href="https://wa.me/654399626">
        <img class="image-logo" src="/assets/images/whatsapp.png">       
    </a>

    <recaptcha [size]="'invisible'" [hide]="false">
    </recaptcha>