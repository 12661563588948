<div class="container-fluid header-page">
    <div class="row">
        <div class="col-md-12">
            <img class="" src="/assets/images/equipo_docente/image_group_a2.png" />
        </div>
    </div>
</div>
<div class="container-fluid body-page">
    <div class="row">
        <div class="col-md-12"> 
            <div class="decor-page-left"></div>
            <div class="body-page-content">

                <div class="team-selector-list">
                    <div class="team-selector-container" *ngFor="let team of listTeam" (click)="selectTeam(team)" [ngClass]="{'selected': team.id == selectedTeam.id}">
                        <div class="team-selector">
                            <img class="default" [src]="'/assets/images/equipo_docente/'+team.imageDefault" />
                            <img class="selected" [src]="'/assets/images/equipo_docente/'+team.imageSelected" />
                        </div>
                        <div class="team-selector-name" [innerHTML]="nameBr(team.name)">
                        </div>
                    </div>
                </div>
                
                <div class="team-list">
                    <div class="team-element">
                        <div class="t-e-image">
                            <img class="selected" [src]="'/assets/images/equipo_docente/'+selectedTeam.imageSelected" />
                        </div>
                        <div class="t-e-name">
                            {{selectedTeam.name}}
                        </div>
                        <div class="t-e-desc" [innerHTML]="selectedTeam.desc">
                        </div>
                        
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>