import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageInicioComponent } from './pages/page-inicio/page-inicio.component';
import { PageElCentroComponent } from './pages/page-el-centro/page-el-centro.component';
import { PageEquipoDocenteComponent } from './pages/page-equipo-docente/page-equipo-docente.component';
import { PageInformacionCicloComponent } from './pages/page-informacion-ciclo/page-informacion-ciclo.component';
import { PagePlanEstudiosComponent } from './pages/page-plan-estudios/page-plan-estudios.component';
import { PagePruebaDeAccesoComponent } from './pages/page-prueba-de-acceso/page-prueba-de-acceso.component';
import { PageQuienesSomosComponent } from './pages/page-quienes-somos/page-quienes-somos.component';
import { PageSalidasProfesionalesComponent } from './pages/page-salidas-profesionales/page-salidas-profesionales.component';
import { PageInicioYellowComponent } from './pages/page-inicio-yellow/page-inicio-yellow.component';
import { PageDocumentacionComponent } from './pages/page-documentacion/page-documentacion.component';
import { PageInicioYellowTwoComponent } from './pages/page-inicio-yellow-two/page-inicio-yellow-two.component';
import { PageInicioYellowThreeComponent } from './pages/page-inicio-yellow-three/page-inicio-yellow-three.component';
import { EquipoDocenteTwoComponent } from './pages/equipo-docente-two/equipo-docente-two.component';
import { HomePageComponent } from './final/home-page/home-page.component';
import { EquipoDocentePageComponent } from './final/equipo-docente-page/equipo-docente-page.component';
import { QuienesSomosPageComponent } from './final/quienes-somos-page/quienes-somos-page.component';
import { ElCentroPageComponent } from './final/el-centro-page/el-centro-page.component';
import { ElCicloPageComponent } from './final/el-ciclo-page/el-ciclo-page.component';
import { PruebaAccesoPageComponent } from './final/prueba-acceso-page/prueba-acceso-page.component';
import { RecaptchaComponent } from './pages/recaptcha/recaptcha.component';
import { CondicionesDeUsoComponent } from './legal/condiciones-de-uso/condiciones-de-uso.component';
import { PoliticaCookiesComponent } from './legal/politica-cookies/politica-cookies.component';
import { PoliticaPrivacidadComponent } from './legal/politica-privacidad/politica-privacidad.component';
import { ClausulasLegalesComponent } from './legal/clausulas-legales/clausulas-legales.component';
import { TablonDocumentosComponent } from './final/tablon-documentos/tablon-documentos.component';

const routes: Routes = [
  
  { path: 'two', component: PageInicioYellowTwoComponent },
  { path: 'three', component: PageInicioYellowThreeComponent },
  { path: 'old', component: PageInicioComponent },
  { path: 'contacto', component: PageInicioComponent },  
  { path: 'equipo-docente-two', component: EquipoDocenteTwoComponent },
  { path: 'informacion-ciclo', component: PageInformacionCicloComponent },
  { path: 'plan-estudios', component: PagePlanEstudiosComponent },  
  
  { path: 'salidas-profesionales', component: PageSalidasProfesionalesComponent },


  { path: 'recaptcha', component: RecaptchaComponent },

  // final
  { path: '', component: HomePageComponent },
  { path: 'inicio', component: HomePageComponent },
  { path: 'equipo-docente', component: EquipoDocentePageComponent },
  { path: 'quienes-somos', component: QuienesSomosPageComponent },
  { path: 'el-ciclo', component: ElCicloPageComponent },
  { path: 'prueba-acceso', component: PruebaAccesoPageComponent },
  { path: 'el-centro', component: ElCentroPageComponent },
  { path: 'tablon-doc-oficiales-generalitat', component: TablonDocumentosComponent },
  //legal  
  { path: 'condiciones-de-uso', component: CondicionesDeUsoComponent },
  { path: 'politica-cookies', component: PoliticaCookiesComponent },
  { path: 'politica-privacidad-redes-sociales', component: PoliticaPrivacidadComponent },
  { path: 'clausulas-legales', component: ClausulasLegalesComponent },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }




