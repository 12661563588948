<div class="container-fluid body-page back-paper-black">
    <div class="decor-page-left"></div>
    <img class="multi-arrow-decor" src="/assets/images/decors/multi_arrow_a3.png" />
    <div class="row">
        <div class="col-md-12 d-none d-lg-block" style="padding-top: 70px;"> 
            <app-title-decor [firstLine]="'El ciclo'" [fontSize]="5" [fontSizeMetric]="'vw'" [numberDecors]="2"></app-title-decor>
        </div>
        <div class="col-md-12 d-lg-none" style="padding-top: 70px;"> 
            <app-title-decor [firstLine]="'El ciclo'" [fontSize]="11" [fontSizeMetric]="'vw'" [numberDecors]="2"></app-title-decor>
        </div>
    </div>
    <div class="limit-widht">
        <div class="row">           
            <div class="col-md-12 content-desc"> 
                <p>El <b>Ciclo Formativo</b> de Grado Medio de Técnicas en <b>Danzas Urbanas</b> regulado por el Decreto 16/2021 del 9 de marzo es un título propio de la Generalitat de Catalunya, <b>el primero</b> en todo el estado relacionado con las danzas urbanas. Este CFGM te otorgará el <b>título de Técnico/a en Danzas Urbanas</b> y por ello todo el contenido del curso esta sujeto al curríulum establecido en el decreto.</p>
                <p>Este <b>título</b> propio de la Generalitat de Catalunya tiene efectos <b>profesionales y laborales</b>, a efectos académicos según indica el Decreto 16/2021 podrá ser objeto de reconocimiento y/o convalidación.</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 content-triangle-decor">  
            <img src="/assets/images/triangle_breka_photo_decor_a4.png" />
        </div>
    </div>
    <div class="limit-widht">
        <div class="row">           
            <div class="col-md-12 content-desc"> 
                <h1> El título de Técnico/a en Danzas Urbanas es propio de la Generalitat de Catalunya con efectos laborales y profesionales. es el único oficial que existe.</h1>
                <div class="generalitat">
                    <img src="/assets/images/logo_generalitat_e1.png" />    
                </div>
                            
            </div>
        </div>
    </div>
    <div class="limit-widht">
        <div class="row">           
            <div class="col-md-12 content-desc"> 
               <h3>¿Por qué es tan importante?</h3>
                <p>Este <b>título</b> no solo es el <b>primero</b> en regular el sector de las danzas urbanas de forma oficial. No solo profesionalizará y ofrecerá por fin una <b>formación de calidad</b>, sino que dignifica, reconoce y da lugar a <b>nivel institucional y social</b> a los bailarines, docentes y coreógrafos de danza urbana. Tras muchos años en los que nuestra profesión ha sufrido en innumerables ocasiones intrusismo, infravaloración a todos los niveles y hasta explotación laboral o condiciones más que cuestionables. Todo ello, entre otros aspectos, no estar regulados ni tener una <b>titulación oficial</b>. Por primera vez se nos pone en el mapa, <b>el arte gana</b>, todos nosotros ganamos. Este es el motivo por el cual sido tan celebrado por toda la escena y es un verdadero honor impartirlo en nuestro centro.</p>
            </div>
        </div>
    </div>
    <div class="limit-widht">
        <div class="row">           
            <div class="col-md-12 content-desc"> 
               <h3>¿Qué salidas profesionales tiene?</h3>
                <p>Tu <b>titulación oficial</b> de Técnico/a en <b>Danzas Urbanas</b> te dará las herramientas necesarias para poder <b>trabajar como bailarín/a</b> en compañías o shows de todo tipo, asistente de coreografía, dinamizador de grupos de danza urbana entre otros.</p>
            </div>
        </div>
    </div>
    
</div>
<div class="bottom-image-decor">
    <img src="/assets/images/bottom_image/bottom_img_4.jpg" />
</div>

<div class="container-fluid bottom-body-page back-paper-revert-black">
    <div class="decor-page-left"></div>
    <div class="limit-widht">
        <div class="row">
            <div class="col-md-12"> 
                <h1>Asignaturas</h1>
                <p>El <b>CFGM</b> divide en <b>2 cursos</b> sus 1466 horas de formación tanto <b>práctica</b> como <b>teórica</b>. El horario será de lunes a viernes de <b>9.00-13.30h o 14.30</b> dependiendo del curso y día.</p>
                
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 d-none d-lg-block">
                <table>
                    <thead>
                        <tr>
                            <th>Asignatura</th>
                            <th>Horas</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td>Técnica Hip-Hop</td><td>132h</td></tr>
                        <tr><td>Técnica Breaking</td><td>132h</td></tr>
                        <tr><td>Técnica Popping</td><td>132h</td></tr>
                        <tr><td>Técnica Locking</td><td>132h</td></tr>
                        <tr><td>Taller Coreográfico y Práctica Escénica</td><td>66h</td></tr>
                        <tr><td>Otras Técnicas de Danza Urbana: Dancehall o Técnica Heels y Comercial Dance</td><td>99h</td></tr>
                        <tr><td>Pedagogía y gestión de grupos de Danza Urbana Adolescente e Infantil</td><td>39h</td></tr>
                        <tr><td>Audiciones, Interpretación a cámara y Presencia Escénica </td><td>40h</td></tr>
                        <tr><td>Prácticas: docente, bailarín/a, coreógrafo/a danzas urbanas </td><td>100h</td></tr>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-6 d-none d-lg-block">
                <table>
                    <thead>
                        <tr>
                            <th>Asignatura</th>
                            <th>Horas</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td>Base técnica de Danza Clásica para la danza urbana </td><td>66h</td></tr>
                        <tr><td>Base técnica de Danza Contemporánea para la danza urbana</td><td>66h</td></tr>
                        <tr><td>Organización de actividades y Gestión y animación de grupos</td><td>66h</td></tr>
                        <tr><td>Conocimiento del cuerpo </td><td>33h</td></tr>
                        <tr><td>Preparación Física </td><td>99h</td></tr>
                        <tr><td>Formación y Orientación laboral </td><td>66h</td></tr>
                        <tr><td>Inglés </td><td>66h</td></tr>                        
                        <tr><td>Trabajo Final </td><td>66h</td></tr>
                    </tbody>
                </table>
            </div>

            <div class="col-lg-12 d-lg-none">
                <table>
                    <thead>
                        <tr>
                            <th>Asignatura</th>
                            <th>Horas</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td>Técnica Hip-Hop</td><td>132h</td></tr>
                        <tr><td>Técnica Breaking</td><td>132h</td></tr>
                        <tr><td>Técnica Popping</td><td>132h</td></tr>
                        <tr><td>Técnica Locking</td><td>132h</td></tr>
                        <tr><td>Taller Coreográfico y Práctica Escénica</td><td>66h</td></tr>
                        <tr><td>Otras Técnicas de Danza Urbana: Dancehall o Técnica Heels y Comercial Dance</td><td>99h</td></tr>
                        <tr><td>Pedagogía y gestión de grupos de Danza Urbana Adolescente e Infantil</td><td>39h</td></tr>
                        <tr><td>Audiciones, Interpretación a cámara y Presencia Escénica </td><td>40h</td></tr>
                        <tr><td>Base técnica de Danza Clásica para la danza urbana </td><td>66h</td></tr>
                        <tr><td>Base técnica de Danza Contemporánea para la danza urbana</td><td>66h</td></tr>
                        <tr><td>Organización de actividades y Gestión y animación de grupos</td><td>66h</td></tr>
                        <tr><td>Conocimiento del cuerpo </td><td>33h</td></tr>
                        <tr><td>Preparación Física </td><td>99h</td></tr>
                        <tr><td>Formación y Orientación laboral </td><td>66h</td></tr>
                        <tr><td>Inglés </td><td>66h</td></tr>
                        <tr><td>Prácticas: docente, bailarín/a, coreógrafo/a danzas urbanas </td><td>100h</td></tr>
                        <tr><td>Trabajo Final </td><td>66h</td></tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">

            </div>
        </div>
    </div>
</div>