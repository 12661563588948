import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tablon-documentos',
  templateUrl: './tablon-documentos.component.html',
  styleUrls: ['./tablon-documentos.component.scss']
})
export class TablonDocumentosComponent implements OnInit {

  public listDocs: any[] = [];

  public urlBase: string = 'https://docs.mabdancecenter.com/docfiles/';

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    let url = 'https://docs.mabdancecenter.com/docfiles/files_docs_manager.json';
    this.http.get(url).subscribe((response:any) => {
      this.listDocs = response.docs;
      
    });
  }


  openDoc(doc:any){

  }

}
