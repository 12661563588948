<div style="min-height: 700px; margin-top: 200px;">

        <div>
            <button (click)="testCaptcha()" style="background-color: blueviolet; color: #fff; border: 0; font-size: 40px; margin: 0 auto;">TEST</button>
        </div>
</div>

<recaptcha 
    [size]="'invisible'"
    [hide]="false" 
    (captchaResponse)="onCaptchaResponse($event)">
</recaptcha>