<section class="home-main-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6 offset-sm-0 col-md-5 offset-md-1 col-xl-3 offset-xl-1">
                <h1 class="home-main-section-title">Ciclo Formativo de Grado Medio de <span style="color: #bd0505;">Danzas Urbanas</span></h1>
                <!--<h1 class="home-main-section-desc">MAB Dance Center será la primera escuela de danza en Barcelona en impartir el Ciclo Formativo de Grado Medio de Danzas Urbanas, un título propio de la Generalitat de Catalunya.</h1>-->
            </div>
            <div class="col-sm-6 offset-sm-0 col-md-5 offset-md-1 col-xl-3 offset-xl-4">
                <div class="home-main-section-form">
                    <form>
                        <div class="mb-3">
                            <label for="inputName" class="form-label">Nombre</label>
                            <input type="text" class="form-control" id="inputName">
                        </div>
                        <div class="mb-3">
                            <label for="inputLastname" class="form-label">Apellidos</label>
                            <input type="text" class="form-control" id="inputLastname">
                        </div>
                        <div class="mb-3">
                            <label for="inputEmail" class="form-label">Email</label>
                            <input type="email" class="form-control" id="inputEmail">
                        </div>
                        <div class="mb-3">
                            <label for="inputPhone" class="form-label">Teléfono</label>
                            <input type="text" class="form-control" id="inputPhone">
                        </div>
                        <div class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="checkboxTerms">
                            <label class="form-check-label" for="checkboxTerms">He leído y comprendo la Política de Privacidad y los Términos de uso de la página web.</label>
                        </div>
                        <div class="send-button-container">
                            <button type="submit" class="send-button">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="home-second-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 offset-md-1 col-xl-7 offset-xl-1">
                <div class="home-second-section-description">
                    <hr /> Haz de tu pasión tu profesión, cursando este ciclo obtendrás un titulo oficial y recibirás toda la formación teórica y práctica que necesitas para dedicarte profesionalmente a las danzas urbanas.

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-10 offset-md-1 col-xl-10 offset-xl-1">
                <div class="home-second-section-description">
                    <hr /> MAB Dance Center será la primera escuela de danza en Barcelona en impartir el Ciclo Formativo de Grado Medio de Danzas Urbanas, un título propio de la Generalitat de Catalunya.
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-third-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4 offset-md-1">
                <div class="home-third-section-image">
                </div>
            </div>
            <div class="col-md-5 offset-md-1">
                <div class="home-third-section-list">
                    <ul>
                        <li><span><i class="fas fa-user-graduate"></i></span> Aprende con profesionales experimentados</li>
                        <li><span><i class="fas fa-rocket"></i></span> Descubre todo tu potencial</li>
                        <li><span><i class="fas fa-bullhorn"></i></span> Tareas siempre guiadas y asistidas</li>
                        <li><span><i class="fas fa-hourglass-half"></i></span> Buena distribución de los tiempos de aprendizaje</li>
                        <li><span><i class="fas fa-kaaba"></i></span> Instalaciones adecuadas para la actividad</li>
                    </ul>
                    <img class="decor-shape" src="/assets/images/shape35_red.png">
                </div>
            </div>
        </div>
    </div>
</section>


<section class="home-fourth-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 offset-lg-1 col-md-0">
                <div class="home-fourth-section-decor-left">
                    <h1>Instalaciones para la danza</h1>
                    <p>Disfruta de unas instalaciones diseñadas para tener el espacio suficiente para realizar las clases así como una ventilación adecuada.</p>
                </div>
            </div>
            <div class="col-lg-4 offset-lg-1 col-md-12">
                <div class="home-fourth-section-video">
                    <iframe src="https://www.youtube.com/embed/Y2jap4aCw74?controls=0&mute=1&autoplay=1&loop=1&playlist=Y2jap4aCw74" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                </div>
            </div>
            <div class="col-lg-2 col-md-0">
                <div class="home-fourth-section-decor-right">
                    <img class="decor-shape-1" src="/assets/images/shape33_red.png">
                    <img class="decor-shape-2" src="/assets/images/decors/shape30_a_red.png">
                </div>
            </div>
        </div>
    </div>
</section>


<section class="home-fifth-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="feature-info">
                    <div class="feature-info-title">
                        <i class="fas fa-walking"></i> Lorem ipsum
                    </div>
                    <div class="feature-info-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse maiores ut vel rem aperiam odi Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    </div>
                </div>

                <div class="feature-info">
                    <div class="feature-info-title">
                        <i class="fas fa-thumbtack"></i> Adipisicing
                    </div>
                    <div class="feature-info-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse maiores ut vel rem aperiam odi Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    </div>
                </div>

                <div class="feature-info">
                    <div class="feature-info-title">
                        <i class="fas fa-star-of-life"></i> Aperiam odi
                    </div>
                    <div class="feature-info-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse maiores ut vel rem aperiam odi Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    </div>
                </div>

                <!-- <div class="feature-info">
                    <div class="feature-info-icon">
                        <i class="fas fa-trophy"></i>
                    </div>
                    <div class="feature-info-title">
                        Consectetur
                    </div>
                    <div class="feature-info-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse maiores ut vel rem aperiam odi
                    </div>
                </div>-->
            </div>
        </div>
    </div>
</section>