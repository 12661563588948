<div class="container">
    <div class="row">
        <div class="col">
            <h1>Política de privacidad y redes sociales </h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
                <p>De conformidad con lo establecido en la normativa vigente en Protección de Datos de Carácter Personal (RGPD) y la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE), MARIA AGUILAR BOSQUE informa a los usuarios, que ha procedido a crear un perfil en la/s Red/es Social/es Facebook, Instagram, con la finalidad principal de publicitar sus productos y servicios.</p>
                <p>Datos de MARIA AGUILAR BOSQUE: CIF: 47755465J DIRECCIÓN: CARRER MALALTS 79 08030, BARCELONA CORREO ELECTRÓNICO: INFO@MABDANCECENTER DOMINIO WEB: WWW.MABDANCECENTER.COM<br>
                El usuario dispone de un perfil en la misma Red Social y ha decidido unirse a la página creada por MARIA AGUILAR BOSQUE, mostrando así interés en la información que se publicite en la Red. Al unirse a nuestra página, nos facilita su consentimiento para el tratamiento de aquellos datos personales publicados en su perfil.</p>
                <p>El usuario puede acceder en todo momento a las políticas de privacidad de la propia Red Social, así como configurar su perfil para garantizar su privacidad.</p>
                <p>MARIA AGUILAR BOSQUE tiene acceso y trata aquella información pública del usuario, en especial, su nombre de contacto. Estos datos, sólo son utilizados dentro de la propia Red Social. No son incorporados a ningún fichero.</p>
                <p>En relación a los derechos de acceso, rectificación, cancelación y oposición, de los que usted dispone y que pueden ser ejercitados ante MARIA AGUILAR BOSQUE, de acuerdo con la RGPD, debe tener en cuenta los siguientes matices: Acceso: Vendrá definido por la funcionalidad de la Red Social y la capacidad de acceso a la información de los perfiles de los usuarios. Rectificación: Sólo podrá satisfacerse en relación a aquella información que se encuentre bajo el control de MARIA AGUILAR BOSQUE, por ejemplo, eliminar comentarios publicados en la propia página. Normalmente, este derecho deberá ejercerlo ante la Red Social. Cancelación y/u Oposición: Como en el caso anterior, sólo podrá satisfacerse en relación a aquella información que se encuentre bajo el control de MARIA AGUILAR BOSQUE, por ejemplo, dejar de estar unido al perfil.</p>
                <p>MARIA AGUILAR BOSQUE realizará las siguientes actuaciones: Acceso a la información pública del perfil. Publicación en el perfil del usuario de toda aquella información ya publicada en la página de MARIA AGUILAR BOSQUE. Enviar mensajes personales e individuales a través de los canales de la Red Social. Actualizaciones del estado de la página que se publicarán en el perfil del usuario.</p>
                <p>El usuario siempre puede controlar sus conexiones, eliminar los contenidos que dejen de interesarle y restringir con quién comparte sus conexiones, para ello deberá acceder a su configuración de privacidad.</p>
                <h2>Publicaciones</h2>
                <p>El usuario, una vez unido a la página de MARIA AGUILAR BOSQUE, podrá publicar en ésta última comentarios, enlaces, imágenes o fotografías o cualquier otro tipo de contenido multimedia soportado por la Red Social. El usuario, en todos los casos, debe ser el titular de los mismos, gozar de los derechos de autor y de propiedad intelectual o contar con el consentimiento de los terceros afectados. Se prohíbe expresamente cualquier publicación en la página, ya sean textos, gráficos, fotografías, vídeos, etc. que atenten o sean susceptibles de atentar contra la moral, la ética, el buen gusto o el decoro, y/o que infrinjan, violen o quebranten los derechos de propiedad intelectual o industrial, el derecho a la imagen o la Ley. En estos casos, MARIA AGUILAR BOSQUE se reserva el derecho a retirar de inmediato el contenido, pudiendo solicitar el bloqueo permanente del usuario.</p>
                <p>MARIA AGUILAR BOSQUE no se hará responsable de los contenidos que libremente ha publicado un usuario.</p>
                <p>El usuario debe tener presente que sus publicaciones serán conocidas por los otros usuarios, por lo que él mismo es el principal responsable de su privacidad.</p>
                <p>Las imágenes que puedan publicarse en la página no serán almacenadas en ningún fichero por parte de MARIA AGUILAR BOSQUE, pero sí que permanecerán en la Red Social.</p>
                <h2>Concursos y promociones</h2>
                <p>MARIA AGUILAR BOSQUE se reserva el derecho a realizar concursos y promociones, en los que podrá participar el usuario unido a su página. Las bases de cada uno de ellos, cuando se utilice para ello la plataforma de la Red Social, serán publicadas en la misma. Cumpliendo siempre con la LSSI-CE y con cualquier otra norma que le sea de aplicación.</p>
                <p>La Red Social no patrocina, avala ni administra, de modo alguno, ninguna de nuestras promociones, ni está asociada a ninguna de ellas.</p>
                <h2>Publicidad</h2>
                <p>MARIA AGUILAR BOSQUE utilizará la Red Social para publicitar sus productos y servicios, en todo caso, si decide tratar sus datos de contacto para realizar acciones directas de prospección comercial, será siempre, cumpliendo con las exigencias legales de la RGPD y de la LSSI-CE.</p>
                <p>No se considerará publicidad el hecho de recomendar a otros usuarios la página de MARIA AGUILAR BOSQUE para que también ellos puedan disfrutar de las promociones o estar informados de su actividad.</p>
                <p>A continuación detallamos el enlace a la política de privacidad de la Red Social:</p>
                <ul>
                    <li>Facebook: <a href="https://www.facebook.com/help/323540651073243/">https://www.facebook.com/help/...</a></li>
                    <li>Instagram: <a href="http://instagram.com/about/legal/privacy/">http://instagram.com/about/legal/...</a></li>
                </ul>  
        </div>
    </div>

</div>

<div class="container-fluid footer-separation">

</div>