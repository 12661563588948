<div class="container">
    <div class="row">
        <div class="col">
            <h1>Prueba de acceso</h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p>Si quieres cursar estudios de FP pero no cuentas con los requisitos académicos para acceder, tienes la opción de incorporarte a estas enseñanzas superando una prueba de acceso.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h2>¿Qué es la prueba de acceso a CFGM de FP? ¿Quién se puede presentar?</h2>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p>Las pruebas de acceso a los ciclos formativos de grado medio de FP son los exámenes que deben superar todas aquellas personas que desean cursar estos estudios y no cumplen con los requisitos académicos de acceso. </p>
            <p>Conviene recordar que los requisitos académicos de acceso a los ciclos formativos de grado medio de FP son:</p>
            <ul>
                <li>Contar con el título de Educación Secundaria Obligatoria (ESO), o equivalente a efectos académicos.</li>
                <li>Estar en posesión de un título de FP Básica.</li>
                <li>Estar en posesión de un título de Técnico de FP (es decir, haber superado previamente otro ciclo de grado medio)</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h2>¿En qué consiste la prueba de acceso a los CFGM de FP?</h2>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p>Esta prueba es común para todos los ciclos formativos y evalúa los contenidos impartidos durante la Educación Secundaria Obligatoria. </p>
            <p>Los contenidos de la prueba se organizan, generalmente, en tres ámbitos de conocimiento:</p>
            <ul>
                <li>Ámbito de comunicación: contempla los exámenes de Lengua Castellana y Literatura, Lengua Extranjera (inglés o francés)</li>
                <li>Ámbito social: incluye los exámenes del ámbito de Ciencias Sociales y Geografía e Historia.</li>
                <li>Ámbito científico-tecnológico: comprende los exámenes de Matemáticas, Física y Química, y Biología y Geología.</li>
            </ul>
        </div>
    </div>


    <div class="row">
        <div class="col">
            <p></p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p></p>
        </div>
    </div>



</div>

<div class="container-fluid footer-separation">

</div>