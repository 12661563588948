<section class="first-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 paper-part">
                <span class="line-1">CICLO FORMATIVO</span>
                <span class="line-2">GRADO MEDIO</span>
                <span class="line-3">DANZAS<br>URBANAS</span>
            </div>
            <div class="col-md-5 video-part">
                <iframe class="background-video" src="https://www.youtube.com/embed/KhEtqwEFsHU?controls=0&mute=1&autoplay=1&loop=1&playlist=KhEtqwEFsHU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div class="background-video-shaow">
                    <div class="button-insc">
                        <div class="button-insc-border">
                            Inscríbete!
                        </div>
                    </div>
                </div>
            </div>
            <!--<img class="break-paper-1" src="/assets/images/break_papper_g1.png">-->
            <!--<img class="break-paper-2" src="/assets/images/break_papper_g1.png">-->
        </div>
    </div>
</section>
<section class="second-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 dancer-model-container">
                <img src="/assets/images/dance_model_a1.png">
            </div>
            <div class="col-md-3 offset-md-1 form-info-container">
                <div class="form-info">
                    <h3>Recibe información</h3>
                    <label for="form_name" class="form-label">Nombre</label>
                    <input type="text" id="form_name" class="form-control" placeholder="Escribe tu nombre" />
                    <label for="form_last_name" class="form-label">Apellidos</label>
                    <input type="text" id="form_last_name" class="form-control" placeholder="Primer y segundo apellido" />
                    <label for="form_last_phone" class="form-label" >Teléfono</label>
                    <input type="text" id="form_last_phone" class="form-control" placeholder="Teléfono de contacto" />
                    <label for="form_last_email" class="form-label">Email</label>
                    <input type="email" id="form_last_email" class="form-control" placeholder="Correo electrónico" />
                    
                    <button type="button">Enviar</button>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="third-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 home-second-text">
                <div class="text-a">
                    LA PRIMERA ESCUELA DE DANZA DE BARCELONA EN IMPARTIR EL CICLO DE GRADO MEDIO DE DANZAS URBANAS DE LA GENERALITAT DE CATALUNYA
                </div>
                <div class="text-b">
                    MAB es casa para todo aquel que ama la danza, sea de forma amateur o con proyección profesional y por ello durante estos años hemos estado celebrando la danza en múltiples formas: festivales, proyectos con valores, obra social, showcase de alumnos, colaboraciones
                    artísticas, shows, exhibiciones, workshops, videoclips con artistas como Tote King ,..
                </div>
            </div>
            <div class="col-md-6">
                <div class="home-second-section-video">
                    <iframe src="https://www.youtube.com/embed/Y2jap4aCw74?controls=0&mute=1&autoplay=1&loop=1&playlist=Y2jap4aCw74" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</section>