import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-prueba-de-acceso',
  templateUrl: './page-prueba-de-acceso.component.html',
  styleUrls: ['./page-prueba-de-acceso.component.scss']
})
export class PagePruebaDeAccesoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
