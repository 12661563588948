import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-el-centro',
  templateUrl: './page-el-centro.component.html',
  styleUrls: ['./page-el-centro.component.scss']
})
export class PageElCentroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
