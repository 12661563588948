<div class="container-fluid body-page">
    <div class="row">
        <div class="col-md-12" style="min-height: 200px; "> 
            
                <app-title-decor [firstLine]="'equipo docente'" [fontSize]="100" [fontSizeMetric]="'px'" [numberDecors]="3"></app-title-decor>
          
        </div>
    </div>
    <div class="row">
        <div class="col-md-12"> 
            <div class="team-selector-container" *ngFor="let team of listTeam" (click)="selectTeam(team)" [ngClass]="{'selected': team.id == selectedTeam.id}">
                <div class="team-selector">
                    <img class="default" [src]="'/assets/images/equipo_docente/'+team.imageDefault" />
                    <img class="selected" [src]="'/assets/images/equipo_docente/'+team.imageSelected" />
                </div>
                <div class="team-selector-name" [innerHTML]="team.name">
                </div>
                <div class="team-selector-active-info">
                    <div class="team-selector-active-info-name" [innerHTML]="team.name">
                    </div>
                    <div class="team-selector-active-info-desc" [innerHTML]="team.desc">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




