<div class="container">
    <div class="row">
        <div class="col">
            <h1>Política de cookies</h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p>Una Cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contenga y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.</p>
            <p>Las cookies son esenciales para el funcionamiento de internet, aportando innumerables ventajas en la prestación de servicios interactivos, facilitándole la navegación y usabilidad de nuestra web.</p>
            <p>La información que le proporcionamos a continuación, le ayudará a comprender los diferentes tipos de cookies:</p>
            <img src="/assets/images/tipos_de_cookies.png" class="table-image-1" />
            <p>Según lo dispuesto en el artículo 22.2 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE), MARIA AGUILAR BOSQUE informa de las cookies utilizadas en nuestra website:</p>
            <img src="/assets/images/tabla_cookies_2.png" class="table-image-1" />
            <p>Google Analytics: Almacena cookies para poder elaborar estadísticas sobre el tráfico y volumen de visitas de esta web. Al utilizar este sitio web está consintiendo el tratamiento de información acerca de usted por Google. Por tanto, el ejercicio de cualquier derecho en este sentido deberá hacerlo comunicando directamente con Google.</p>
            <p>Las cookies de Google Analytics, se almacenan en servidores ubicados en Estados Unidos y se compromete a no compartirla con terceros, excepto en los casos en los que sea necesario para el funcionamiento del sistema o cuando la ley obligue a tal efecto. Según Google no guarda su dirección IP.</p>
            <p>Google Inc. es una compañía adherida a “Privacy Shiels” (escudo de privacidad) que garantiza que todos los datos transferidos serán tratados con un nivel de protección acorde a la normativa europea. Puede consultar información detallada a este respecto a través del siguiente enlace: <a href="https://support.google.com/analytics/answer/6004245" target="_blank">https://support.google.com/a...</a>.</p>
            <p>Si lo desea puede utilizar el Complemento de inhabilitación para navegadores de Google Analytics a través de cuyas instrucciones pueden rechazarse las cookies analíticas de dicho servicio en todos los navegadores. Puede consultar más información al respecto en el siguiente enlace: <a href="https://support.google.com/analytics/answer/6004245" target="_blank">https://tools.google.com/dl...</a></p>
            <h2>Configuración de cookies</h2>
            <p>Asimismo, MARIA AGUILAR BOSQUE informa al usuario de que tiene la posibilidad de configurar su navegador de modo que se le informe de la recepción de cookies, pudiendo, si así lo desea, impedir que sean instaladas en su disco duro.</p>
            <p>A continuación le proporcionamos los enlaces de diversos navegadores, a través de los cuales podrá realizar dicha configuración:</p>
            <ul>
                <li>Firefox desde <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">aquí</a></li>
                <li>Chrome desde <a href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647">aquí</a></li>
                <li>Explorer desde <a href="http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-10">aquí</a></li>
                <li>Safari desde <a href="https://support.apple.com/kb/ph17191?locale=es_ES">aquí</a></li>
                <li>Opera desde <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">aquí</a></li>
            </ul>

            
        </div>
    </div>

</div>

<div class="container-fluid footer-separation">

</div>