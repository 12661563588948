import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-title-decor',
  templateUrl: './title-decor.component.html',
  styleUrls: ['./title-decor.component.scss']
})
export class TitleDecorComponent implements OnInit {

  @Input() firstLine: string = '';
  @Input() secondLine: string = '';
  @Input() thirdLine: string = '';
  
  @Input() numberDecors: number = 2;
  
  @Input() fontSize: number = 20;
  @Input() fontSizeMetric: string = 'vw';
  @Input() color: string = 'vw';

  public outlineArrNum: number[] = [];

  constructor() { }

  ngOnInit(): void {
    for(let i = 0; i < this.numberDecors; i++){
      this.outlineArrNum.push(i);
    }
  }

  getOutlineCss(){
    let lineH = this.fontSize * 0.4;
    //let fon = this.fontSize * 0.4;
    let result = 'line-height: '+lineH+'vw;font-size: '+this.fontSize+this.fontSizeMetric;

    return result;
  }

  getLineCss(numLine: number){
    let lineH = this.fontSize * 0.4;
    if(numLine == 2){
      lineH = this.fontSize * 1.1;
    } else if(numLine == 3){
      lineH = this.fontSize * 0.6;
    }
    let result = 'line-height: '+lineH+'vw;font-size: '+this.fontSize+this.fontSizeMetric;

    return result;
  }
}
