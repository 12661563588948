import { Component, OnInit } from '@angular/core';
/*
import * as CanvasJSAngularChart from '../../../assets/canvasjs/canvasjs.angular.component';
var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;
CanvasJSAngularChart.addColorSet("customColorSet",["#ffcb06", "#ce1249", "#3a943c", "#7f3e83", "#812900", "#2078b6", "#df7f2e", "#e3e3e3"]);
*/

@Component({
  selector: 'app-prueba-acceso-page',
  templateUrl: './prueba-acceso-page.component.html',
  styleUrls: ['./prueba-acceso-page.component.scss']
})
export class PruebaAccesoPageComponent implements OnInit {


  	chartOptions = {
		backgroundColor: "transparent",
    	colorSet: "customColorSet",
			animationEnabled: true,
			theme: "dark2",
			exportEnabled: false,
			title: {
		  		text: ""
			},
			subtitles: [{
				text: ""
			}],
	  	data: [{
  		type: "doughnut", //change type to column, line, area, doughnut, etc
  		indexLabel: "{name} ({y}%)",
      	indexLabelFontSize: 26,
      	indexLabelFontFamily: "HkGroteskBold",
      	indexLabelFontColor: "red",
  		dataPoints: [
  			{ name: "Consciencia Corporal", y: 25, color: "#fad02c", indexLabelFontColor: "#f4d761" },        
  			{ name: "Colocación en el espacio ", y: 2, color: "#c4a010", indexLabelFontColor: "#fad02c" },
  			{ name: "Condiciones Físicas", y: 10, color: "#fad02c", indexLabelFontColor: "#f4d761" },
  			{ name: "Ejecución", y: 25, color: "#c4a010", indexLabelFontColor: "#fad02c" },
  			{ name: "Capacidad de reacción a las correcciones", y: 3, color: "#fad02c", indexLabelFontColor: "#f4d761", indexLabelFontSize: 23 },
  			{ name: "Musicalidad", y: 20, color: "#c4a010", indexLabelFontColor: "#fad02c" },
  			{ name: "Coordinación", y: 10, color: "#fad02c", indexLabelFontColor: "#f4d761" },       
  			{ name: "Creatividad/Freestyle", y: 5, color: "#c4a010", indexLabelFontColor: "#fad02c" }
  		]
	  }]
	};


	chartOptionsResponsive = {
		backgroundColor: "transparent",
    	colorSet: "customColorSet",
			animationEnabled: true,
			theme: "dark2",
			exportEnabled: false,
			title: {
		  		text: ""
			},
			subtitles: [{
				text: ""
			}],
	  	data: [{
  		type: "bar", //change type to column, line, area, doughnut, etc
		showInLegend: false,
  		indexLabel: "¬ {y}%",
      	indexLabelFontSize: 26,
      	indexLabelFontFamily: "HkGroteskBold",
  		dataPoints: [
  			{ label: "Consciencia Corporal", y: 25, color: "#c4a010", indexLabelFontColor: "#fff" },        
  			{ label: "Colocación en el espacio ", y: 2, color: "#c4a010", indexLabelFontColor: "#fff" },
  			{ label: "Condiciones Físicas", y: 10, color: "#c4a010", indexLabelFontColor: "#fff" },
  			{ label: "Ejecución", y: 25, color: "#c4a010", indexLabelFontColor: "#fff" },
  			{ label: "Capacidad de reacción a las correcciones", y: 3, color: "#c4a010", indexLabelFontColor: "#fff", indexLabelFontSize: 23 },
  			{ label: "Musicalidad", y: 20, color: "#c4a010", indexLabelFontColor: "#fff" },
  			{ label: "Coordinación", y: 10, color: "#c4a010", indexLabelFontColor: "#fff" },       
  			{ label: "Creatividad/Freestyle", y: 5, color: "#c4a010", indexLabelFontColor: "#fff" }
  		]
	  }]
	};



  constructor() { }

  ngOnInit(): void {
  }

}
