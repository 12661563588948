import { Component, OnInit } from '@angular/core';

interface teamSelector {
  id: number,
  name: string,
  imageDefault: string,
  imageSelected: string,
  desc: string
}

@Component({
  selector: 'app-equipo-docente-page',
  templateUrl: './equipo-docente-page.component.html',
  styleUrls: ['./equipo-docente-page.component.scss']
})
export class EquipoDocentePageComponent implements OnInit {

  public selectedTeam: teamSelector = {id: 0, name: '', imageDefault: '', imageSelected: '', desc: ''};
  public listTeam: teamSelector[] = [
    {id: 1, name: 'Laura Girona', imageDefault: 'laura_girona_black_a2.png', imageSelected: 'laura_girona_a2.png', desc: ''
      +'<p>Laura empezó a practicar danzas urbanas con 14 años, y a los 17 conoció el mundo de la acrobacia. Se dio cuenta que el ejercicio físico era una de las actividades que más le motivaban y que consideraba imprescindible para poder tener una vida sana y plena, así que decidió estudiar Ciencias de la Actividad Física y el Deporte, y posteriormente el Máster de Educación Secundaria Obligatoria y Bachillerato, FP y Enseñanzas de Idiomas con la especialidad de Educación Física. Durante estos años ha seguido practicando danzas urbanas y acrobacia y se ha adentrado en el mundo del fitness. Actualmente, es entrenadora personal, profesora de danzas urbanas y profesora de acrobacia. Es profesora de “Conocimiento del cuerpo y Preparación Física para la danza urbana”, una asignatura que considera muy importante para poder realizar la práctica de la danza urbana de forma más segura y eficiente. Laura no solamente tiene los conocimientos para dar una buena base de entrenamiento, estiramientos, nutrición, conocimiento del cuerpo, posibles lesiones,... Comprende a la perfección las necesidades físicas que podemos experimentar como bailarines y por ello su perspectiva es la ideal para la formación que impartimos. Si algo destaca en Laura es su energía, su motivación y su compromiso con lo que hace. Le encanta aprender cosas nuevas, pero más le gusta enseñar y poder transmitir su pasión por el movimiento a sus alumnos.</p>'
    },
    {id: 2, name: 'Daniel Sábado', imageDefault: 'daniel_sabado_black_a1.png', imageSelected: 'daniel_sabado_a1.png', desc: ''
      +'<p>Daniel Sábado Soriano es actor, técnico de sonido y bailarín profesional de danzas urbanas desde hace 14 años. Lleva formándose desde entonces en diversas escuelas, compañías y con profesores muy influyentes a nivel global, además de ser autodidacta. Ha ganado diversos premios en competiciones en solitario y en grupo, tanto en España como en Europa, además de premios audiovisuales. Está especializado en Hiphop y Dancehall y ha viajado por Jamaica para profundizar en la cultura del Dancehall, formándose con profesores nativos de allí, para conocer de raíz esta cultura de la que se declara amante y de la cual no ha dejado de estudiar y de aprender más cada día. Forma parte de la compañía de Dancehall One Love Inna Di Place, de la que ha adquirido también muchos conocimientos. Daniel no solo tiene un basto conocimiento sobre múltiples de las danzas urbanas, es un apasionado de la cultura del Dancehall y de lo que hace. Sabiamos que era el mejor para inspirar a nuestros alumnos y trasladaros todo lo que podáis imaginar sobre esta danza urbana.</p>'
    },
    {id: 3, name: 'Nekane Morera', imageDefault: 'nekane_moreda_black_a2.png', imageSelected: 'nekane_moreda_a2.png', desc: ''
      +'<p>Nekane ha viajado y aprendido en las mejores escuelas y con los mejores profesores internacionales de Francia, Polonia, Italia, Alemania, Japón, Los Angeles, San Francisco. Fundadora y organizadora del primer festival coreográfico de danzas urbanas del país: el Snap Festival. Este festival no solamente es importante por ser pionero sino porque cambió porque rompió con muchas de las normas que encorsetaban a nivel creativo a los coreógrafos: les dio libertad creativa absoluta y permitió que muchos bailarines bailaran con diferentes compañías, se interrelacionaran y compartieran la cultura desde otro punto de vista. Fundadora de las escuelas de danza urbana Boogie Town y General Giap. Formó parte de una de las compañías pioneras en el estado, la emblemática Tribu Urbana y formó la suya propia: Chief Rockers, galardonada y respetada por la comunidad de bailarines por su esencia y sello propio. Se ha dedicado a la docencia desde hace más de 20 años en escuelas como Coco Comin y formando a bailarines/as de primera línea hoy en día. Si hablamos de ella lo justo sería decir que es leyenda de las danzas urbanas y por ello también formó parte de la Comisión que trabajó con el Departament D\'Ensenyament para la creación de este CFGM. Vinculada a la cultura Hip Hop de una forma tan genuina y estrecha que no había nadie que fuera a dar mejor nuestras clases de formación profesional de Técnica Hip-Hop.</p>'
    },
    {id: 4, name: 'Marya Aguilar', imageDefault: 'marya_aguilar_black_a1.png', imageSelected: 'marya_aguilar_a1.png', desc: ''
      +'<p>Formada en danzas urbanas en las mejores escuelas a nivel estatal e internacional en ciudades como Los Ángeles, París, Nueva York o Toulouse y con más de 20 años de experiencia en la docencia y producción de espectáculos. Formada también en Coaching y Producción y Gestión de Festivales por SAE Creative Education. Como Bailarina Campeona de España 2006 y 2007, y también a nivel europeo el 2007. Formó parte de las mejores compañías a nivel estatal incluida una de las pioneras en danzas urbanas en el país: Tribu Urbana. Bailarina y coreógrafa en múltiples conciertos, festivales (Medusa, Sónar, RBF,..), giras y videoclips de artistas como Tote King, Maka, Rels B, Kaidy Cain, Vicco, Nelicious,... Productora de eventos y shows en emblemáticas localizaciones como la Sala Razzmatazz, el Hotel W, el Teatro Victoria, Sala Barts,.. En la actualidad, directora de MAB Dance Center, docente, bailarina y CEO, realiza un papel de coach artístico haciendo acompañamientos y soporte a artistas para sacar su máximo potencial. Desde que hace unos años supo que se estaba gestando lo que hoy es este ciclo, sintió que era su próximo objetivo: dar futuro, reconocimiento oficial, formación de calidad a la siguiente generación de bailarines en MAB. Y sí, no ha parado hasta conseguirlo. Ella será la dirigirá y también impartirá algunas de las asignaturas ya que no hay nada que le guste más que la enseñanza.</p>'
    },
    {id: 5, name: 'Nadia Rilo', imageDefault: 'nadia_rilo_black_a1.png', imageSelected: 'nadia_rilo_a1.png', desc: ''
      +'<p>Nadia ha viajado durante toda su vida para formarse con las leyendas y pioneros de la Cultura Hip Hop, profesionales de renombre internacional. Esto la llevó a Francia, EE.UU y Japón donde recibió la mayoría de su formación y le dio la oportunidad de bailar para distintas marcas comerciales y en eventos relacionados con la Cultura Hip Hop a nivel nacional e internacional. Desde pequeña ya sentía curiosidad por la cultura Hip Hop y empezó un trabajo de investigación sobre ésta, ahora intenta transmitir sus conocimientos a la siguiente generación. Se especializó en danzas urbanas, concretamente en Popping y Locking y estas son las asignaturas que impartirá en el CFGM, 20 años de experiencia avalan su trayectoria profesional como coreógrafa y profesora. Ha trabajado en escuelas preparatorias para el Institut del Teatre, como Aula de Dansa y Color, en Coco Comin, y también en escuelas especializadas en danzas urbanas como Mab Dance Center, etc… Ha sido coreógrafa y directora de jóvenes compañías galardonadas con múltiples premios a nivel nacional. Directora y coreógrafa de piezas cortas de Café-teatro. También ha sido jueza en eventos a nivel nacional y ha impartido workshops por todo el territorio nacional. Además de impartir las clases en el CFGM también será nuestra Jefa de Estudios.</p>'
    },
    {id: 6, name: 'Mighty Masta', imageDefault: 'mighty_masta_black_a1.png', imageSelected: 'mighty_masta_a1.png', desc: ''
      +'<p>Bailarín Barcelonés de Breaking con más de 20 años de experiencia, comenzó a bailar en 2001. Durante su trayectoria ha ganado campeonatos de prestigio como el "BATTLE OF THE YEAR", "HIPNOTIK FESTIVAL" o " TOTAL SESSION" y ha sido invitado a competiciones nacionales e internacionales como "RED BULL BC ONE EUROPE" para representar España tanto como participante o jurado. En el apartado artístico, ha realizando espectáculos en "EL GREC"” o el "MERCAT DE LES FLORS"entre otros y ha realizado exhibiciones como "MADONNA DANCERS VS BARCELONA DANCERS" Actualmente es fundador y bailarín de la compañía de danzas urbanas y escénicas Legacy Dance Company, la cual ha realizado espectáculos en algunos de los mejores festivales de danza de España, como “FESTIVAL HOP”, “FESTIVAL DE DANZA CONTEMPORÁNEA BURGOS Y NUEVA YORK”, “FESTIVAL TAC DE VALLADOLID” etc...Obteniendo el mejor show y 1er puesto en el Battle of the year Spain 2018 y 2019. También es Jurado en diferentes competiciones de Breaking y ha realizado diferentes Masterclass de Breaking por toda Cataluña incluyendo sitios de prestigio como el INSTITUT DEL TEATRE. Es Vicepresidente de la comisión de Breaking catalana para las Olimpiadas y certificado por la FEDB como jurado de Breaking nacional. ¿Se os ocurre alguien mejor para impartir las clases de Técnica Breaking?</p>'
    },
    {id: 7, name: 'Irene Gracia', imageDefault: 'irene_gracia_black_a1.png', imageSelected: 'irene_gracia_a1.png', desc: ''
      +'<p>Irene descubrió la danza desde muy pequeña. La cultura Hip-Hop siempre le llamó la atención y con 10 años se inició en el mundo de la danza urbana. Desde allí su vida cambió, ya que la danza pasó a ser su día a día. Con 16 años empezó a impartir clases de danza hasta el día de hoy. Estudió Educación Infantil y más adelante obtuvo el título de tonificación y clases dirigidas. Al acabar sus estudios se dedicó plenamente a la danza. Fusionar sus conocimientos en educación y danza fué algo mágico. Por ello va a impartir asignaturas relacionadas precisamente con la pedagogía y todo lo necesario para ser un buen docente en danzas urbanas. Irene también será nuestra Coordinadora de Ciclo. Como bailarina ha trabajado para artistas como Rels b, Tote king, Kaydy Cain, Indee, Nelicious... También coreógrafa para artistas como Maka e Indee. Su casa siempre fue Mab Dance Center, donde se formó desde los 20 años y más adelante formó parte del profesorado, allí descubrió que la danza es su vida. Actualmente sigue formándose y aprendiendo, investigando nuevas vías de conocimiento en todo lo relativo al movimiento.</p>'
    },
    {id: 8, name: 'Goretti Pié', imageDefault: 'goretti_pie_black_a1.png', imageSelected: 'goretti_pie_a1.png', desc: ''
      +'<p>Graduada en Interpretación de la danza y coreografía por el Conservatorio Superior de Danza del Institut del Teatre. Inició los estudios en danza clásica en su ciudad natal, Valls (Tarragona). En 2013, comienza su formación profesional en danza contemporánea, jazz y modern, que refuerza con la técnica del ballet y complementa con diferentes estilos de danzas urbanas en Peridance Capezio Center de Nueva York, dónde obtiene el certifcado en técnica Limón por la Fundación José Limón. Compagina la formación con la docencia, impartiendo clases de ballet, danza contemporánea y jazz, tanto a nivel amateur como estudiantes y profesionales de la danza; así como sesiones específcas de base técnica, acondicionamiento físico y técnica de improvisación. En 2020 forma parte de la Cia. Métrica Bárbara en el proyecto “De una en una [versos sobre un tejado errante]” con el soporte a la creación de Fira de Tarrega (2020) y L’animal a l’esquena (2021), participando en el Sismograf (2022), Moujuic (2022) y Espai de Carrer (2023) entre los más destacados. Actualmente, amplía su formación como Instructora de Hatha Yoga en la Escuela Internacional de Yoga. Goretti conoce la técnica, movimiento y dinámicas de la danza urbana eso la hace ideal para poder impartir las clases de complemento de danza clásica y contemporánea para la base de los bailarines de danza urbana.</p>'
    }/*,
    {id: 9, name: 'Carol Fleitas', imageDefault: 'carol_black_a1.png', imageSelected: 'carol_a1.png', desc: ''
      +'<p>Profesora de inglés con años de experiencia tanto a grupos como individuales. Bailarina de popping desde hace 7 años, ha recibido formación en diversos países, combinando su pasión por los idiomas y la danza. Actualmente continúa viajando para competir y compartir su baile, así como para profundizar en otras culturas y aprender nuevas lenguas.</p>'
      +'<p>Carolina, profesora de inglés con años de experiencia y bailarina de popping desde hace 7 años, ha recibido formación en diversos países, combinando su pasión por los idiomas y la danza. Impartirá, por tanto, las clases de inglés del ciclo. </p>'
      +'<p>Comenzó su trayectoria en Canarias donde aprendió inglés desde temprana edad para luego comenzar a dedicarse a la enseñanza en la Universidad. Por otro lado, a los 20 años fue introducida en el popping. Allí recibió sus primeras lecciones y años más tarde comenzó a impartir clases a los más pequeños. También con conocimientos en otros ámbitos, ha recibido clases de Breaking, Lockin, Krump y Hip Hop, dándole una amplia visión sobre los diferentes estilos dentro de la danza urbana. A día de hoy, continúa con su formación como profesora de inglés y como bailarina, ya que el idioma le permite viajar y formarse con personas de todas las culturas. Para ella, se trata de algo necesario para cualquier bailarín que quiera proseguir su carrera en otros lugares del mundo así como para viajar, recibir clases y obtener información que no se encuentra disponible en nuestro idioma. Apasionada y creativa, como profesora y bailarina desea transmitirle este entusiasmo a los estudiantes, de modo que puedan perfeccionar su inglés al mismo tiempo que aprenden nuevas cosas acerca de la cultura urbana.</p>'
    }*/



    /*{id: 1, name: 'Laura Girona', imageDefault: 'laura_girona_black_a1.png', imageSelected: 'laura_girona_a1.png', desc: ''
      +'<p>Laura empezó a practicar danzas urbanas con 14 años, y a los 17 conoció el mundo de la acrobacia.</p>'
      +'<p>Se dio cuenta que el ejercicio físico era una de las actividades que más le motivaban y que consideraba imprescindible para poder tener una vida sana y plena, así que decidió estudiar Ciencias de la Actividad Física y el Deporte, y posteriormente el Máster de Educación Secundaria Obligatoria y Bachillerato, FP y Enseñanzas de Idiomas con la especialidad de Educación Física.</p>'
      +'<p>Durante estos años ha seguido practicando danzas urbanas y acrobacia y se ha adentrado en el mundo del fitness.</p>'
      +'<p>Actualmente, es entrenadora personal, profesora de danzas urbanas y profesora de acrobacia.</p>'
      +'<p>Es profesora de “Conocimiento del cuerpo y Preparación Física para la danza urbana”, una asignatura que considera muy importante para poder realizar la práctica de la danza urbana de forma más segura y eficiente.</p>'
      +'<p>Laura no solamente tiene los conocimientos para dar una buena base de entrenamiento, estiramientos, nutrición, conocimiento del cuerpo, posibles lesiones,...</p>'
      +'<p>Comprende a la perfección las necesidades físicas que podemos experimentar como bailarines y por ello su perspectiva es la ideal para la formación que impartimos.</p>'
      +'<p>Si algo destaca en Laura es su energía, su motivación y su compromiso con lo que hace. Le encanta aprender cosas nuevas, pero más le gusta enseñar y poder transmitir su pasión por el movimiento a sus alumnos.</p>'
    },
    {id: 2, name: 'Daniel Sábado', imageDefault: 'daniel_sabado_black_a1.png', imageSelected: 'daniel_sabado_a1.png', desc: ''
      +'<p>Daniel Sábado Soriano es actor, técnico de sonido y bailarín profesional de danzas urbanas desde hace 14 años. Lleva formándose desde entonces en diversas escuelas, compañías y con profesores muy influyentes a nivel global, además de ser autodidacta.</p>'
      +'<p>Ha ganado diversos premios en competiciones en solitario y en grupo, tanto en España como en Europa, además de premios audiovisuales. Está especializado en Hiphop y Dancehall y ha viajado por Jamaica para profundizar en la cultura del Dancehall, formándose con profesores nativos de allí, para conocer de raíz esta cultura de la que se declara amante y de la cual no ha dejado de estudiar y de aprender más cada día. Forma parte de la compañía de Dancehall One Love Inna Di Place, de la que ha adquirido también muchos conocimientos.</p>'
      +'<p>Daniel no solo tiene un basto conocimiento sobre múltiples de las danzas urbanas, es un apasionado de la cultura del Dancehall y de lo que hace.</p>'
      +'<p>Sabiamos que era el mejor para inspirar a nuestros alumnos y trasladaros todo lo que podáis imaginar sobre esta danza urbana.</p>'
    },
    {id: 3, name: 'Nekane Morera', imageDefault: 'nekane_moreda_black_a1.png', imageSelected: 'nekane_moreda_a1.png', desc: ''
      +'<p>Nekane ha viajado y aprendido en las mejores escuelas y con los mejores profesores internacionales de Francia, Polonia, Italia, Alemania, Japón, Los Angeles, San Francisco.</p>'
      +'<p>Fundadora y organizadora del primer festival coreográfico de danzas urbanas del país: el Snap Festival. Este festival no solamente es importante por ser pionero sino porque cambió porque rompió con muchas de las normas que encorsetaban a nivel creativo a los coreógrafos: les dio libertad creativa absoluta y permitió que muchos bailarines bailaran con diferentes compañías, se interrelacionaran y compartieran la cultura desde otro punto de vista.</p>'
      +'<p>Fundadora de las escuelas de danza urbana Boogie Town y General Giap. Formó parte de una de las compañías pioneras en el estado, la emblemática Tribu Urbana y formó la suya propia: Chief Rockers, galardonada y respetada por la comunidad de bailarines por su esencia y sello propio.</p>'
      +'<p>Se ha dedicado a la docencia desde hace más de 20 años en escuelas como Coco Comin y formando a bailarines/as de primera línea hoy en día.</p>'
      +'<p>Si hablamos de ella lo justo sería decir que es leyenda de las danzas urbanas en el estado y por ello también formó parte de la Comisión que trabajó con el Departament D\'Ensenyament para la creación de este CFGM. Vinculada a la cultura Hip-Hop de una forma tan genuina y estrecha que no había nadie que fuera a dar mejor nuestras clases de formación profesional de Técnica Hip-Hop.</p>'
    },
    {id: 4, name: 'Marya Aguilar', imageDefault: 'marya_aguilar_black_a1.png', imageSelected: 'marya_aguilar_a1.png', desc: ''
      +'<p>Formada en danzas urbanas en las mejores escuelas a nivel estatal e internacional en ciudades como Los Ángeles, París, Nueva York o Toulouse y con más de 20 años de experiencia en la docencia y producción de espectáculos. Formada también en Coaching y Producción y Gestión de Festivales por SAE Creative Education.</p>'
      +'<p>Como Bailarina Campeona de España 2006 y 2007, y también a nivel europeo el 2007. Formó parte de las mejores compañías a nivel estatal incluida una de las pioneras en danzas urbanas en el país: Tribu Urbana.</p>'
      +'<p>Bailarina y coreógrafa en múltiples conciertos, festivales (Medusa, Sónar, RBF,..), giras y videoclips de artistas como Tote King, Maka, Rels B, Kaidy Cain, Vicco, Nelicious,...</p>'
      +'<p>Productora de eventos y shows en emblemáticas localizaciones como la Sala Razzmatazz, el Hotel W, el Teatro Victoria, Sala Barts,..</p>'
      +'<p>En la actualidad, directora de MAB Dance Center, docente, bailarina y CEO, realiza un papel de coach artístico haciendo acompañamientos y soporte a artistas para sacar su máximo potencial.</p>'
      +'<p>Desde que hace unos años supo que se estaba gestando lo que hoy es este ciclo, sintió que era su próximo objetivo: dar futuro, reconocimiento oficial, formación de calidad a la siguiente generación de bailarines en MAB. Y sí, no ha parado hasta conseguirlo. Ella será la dirigirá y también impartirá algunas de las asignaturas ya que no hay nada que le guste más que la enseñanza.</p>'
  },
    {id: 5, name: 'Nadia Rilo', imageDefault: 'nadia_rilo_black_a1.png', imageSelected: 'nadia_rilo_a1.png', desc: ''
      +'<p>Nadia ha viajado durante toda su vida para formarse con las leyendas y pioneros de la Cultura Hip Hop,  profesionales de renombre internacional. Esto la llevó a Francia, EE.UU y Japón donde recibió la mayoría de su formación y le dio la oportunidad de bailar para distintas marcas comerciales y en eventos relacionados con la Cultura Hip Hop a nivel nacional e internacional. Desde pequeña ya sentía curiosidad por la cultura Hip Hop y empezó un trabajo de investigación sobre ésta, ahora intenta  transmitir sus conocimientos a la siguiente generación. Se especializó en danzas urbanas, concretamente en Popping y Locking y estas son las asignaturas que impartirá en el CFGM, 20 años de experiencia avalan su trayectoria profesional como coreógrafa y profesora.</p>'
      +'<p>Ha trabajado en escuelas preparatorias para el Institut del Teatre, como Aula de Dansa y Color,  en Coco Comin, y también en escuelas especializadas en danzas urbanas como Mab Dance Center, etc…</p>'
      +'<p>Ha sido coreógrafa y directora  de jóvenes compañías galardonadas con múltiples premios a nivel nacional. Directora y coreógrafa de piezas cortas de Café-teatro. También ha sido jueza en eventos a nivel nacional y ha impartido workshops por todo el territorio nacional.</p>'
      +'<p>Además de impartir las clases en el CFGM también será nuestra Jefa de Estudios.</p>'
    },
    {id: 6, name: 'Mighty Masta', imageDefault: 'mighty_masta_black_a1.png', imageSelected: 'mighty_masta_a1.png', desc: ''
      +'<p>Bailarín Barcelonés de Breaking con más de 20 años de experiencia, comenzó a bailar en 2001.</p>'
      +'<p>Durante su trayectoria ha ganado campeonatos de prestigio como el "BATTLE OF THE YEAR", "HIPNOTIK FESTIVAL" o " TOTAL SESSION" y ha sido invitado a competiciones nacionales e internacionales como "RED BULL BC ONE EUROPE" para representar España tanto como participante o jurado. En el apartado artístico, ha realizando espectáculos en "EL GREC"” o el "MERCAT DE LES FLORS"entre otros y ha realizado exhibiciones como "MADONNA DANCERS VS BARCELONA DANCERS"</p>'
      +'<p>Actualmente es fundador y bailarín de la compañía de danzas urbanas y escénicas Legacy</p>'
      +'<p>Dance Company, la cual ha realizado espectáculos en algunos de los mejores festivales de danza de España, como “FESTIVAL HOP”, “FESTIVAL DE DANZA CONTEMPORÁNEA BURGOS Y NUEVA YORK”, “FESTIVAL TAC DE VALLADOLID” etc...Obteniendo el mejor show y 1er puesto en el Battle of the year Spain 2018 y 2019.</p>'
      +'<p>También es Jurado en diferentes competiciones de Breaking y ha realizado diferentes Masterclass de Breaking por toda Cataluña incluyendo sitios de prestigio como el INSTITUT DEL TEATRE.</p>'
      +'<p>Es Vicepresidente de la comisión de Breaking catalana para las Olimpiadas y certificado por la FEDB como jurado de Breaking nacional.</p>'
      +'<p>¿Se os ocurre alguien mejor para impartir las clases de Técnica Breaking?</p>'
    },
    {id: 7, name: 'Irene Gracia', imageDefault: 'irene_gracia_black_a1.png', imageSelected: 'irene_gracia_a1.png', desc: ''
      +'<p>Irene descubrió la danza desde muy pequeña. La cultura Hip-Hop siempre le llamó la atención y con 10 años se inició en el mundo de la danza urbana. Desde allí su vida cambió,  ya que la danza pasó a ser su día a día. Con 16 años empezó a impartir clases de danza hasta el día de hoy. Estudió Educación Infantil y más adelante obtuvo el título de tonificación y clases dirigidas. Al acabar sus estudios se dedicó plenamente a la danza.</p>'
      +'<p>Fusionar sus conocimientos en educación y danza fué algo mágico. Por ello va a impartir asignaturas relacionadas precisamente con la pedagogía y todo lo necesario para ser un buen docente en danzas urbanas.</p>'
      +'<p>Como bailarina ha trabajado para artistas como Rels b, Tote king, Kaydy Cain, Indee, Nelicious... </p>'
      +'<p>También coreógrafa para artistas como Maka e Indee.</p>'
      +'<p>Su casa siempre fue Mab Dance Center, donde se formó desde los 20 años y más adelante formó parte del profesorado, allí descubrió que la danza es su vida. Actualmente sigue formándose y aprendiendo, investigando nuevas vías de conocimiento en todo lo relativo al movimiento.</p>'
  },
    {id: 8, name: 'Goretti Pié', imageDefault: 'goretti_pie_black_a1.png', imageSelected: 'goretti_pie_a1.png', desc: ''
      +'<p>Graduada en Interpretación de la danza y coreografía por el Conservatorio Superior de Danza del Institut del Teatre (2020). Inició los estudios en danza clásica en su ciudad natal, Valls (Tarragona). En 2013, comienza su formación profesional en danza contemporánea, jazz y modern, que refuerza con la técnica del ballet y complementa con diferentes estilos de danzas urbanas en Peridance Capezio Center de Nueva York, dónde obtiene el certifcado en técnica Limón por la Fundación José Limón. </p>'
      +'<p>Compagina la formación con la docencia, impartiendo clases de ballet, danza contemporánea y jazz, tanto a nivel amateur como estudiantes y profesionales de la danza; así como sesiones específcas de base técnica, acondicionamiento físico y técnica de improvisación. En 2020 forma parte de la Cia. Métrica Bárbara en el proyecto “De una en una [versos sobre un tejado errante]” con el soporte a la creación de Fira de Tarrega (2020) y L’animal a l’esquena (2021), participando en el Sismograf (2022), Moujuic (2022) y Espai de Carrer (2023) entre los más destacados.</p>'
      +'<p>Actualmente, amplía su formación como Instructora de Hatha Yoga en la Escuela Internacional de Yoga. </p>'
      +'<p>Goretti conoce la técnica, movimiento y dinámicas de la danza urbana eso la hace ideal para poder impartir las clases de complemento de danza clásica y contemporánea  para la base de los bailarines de danza urbana.</p>'
  }*/
  ];

  constructor() { }

  ngOnInit(): void {
    //this.selectedTeam = this.listTeam[3];
  }

  selectTeam(team:teamSelector){
    this.selectedTeam = team;
    console.log(team);
    
  }

  closeSelectedTeam(){
    this.selectTeam({id: 0, name: '', imageDefault: '', imageSelected: '', desc: ''});
    setTimeout(() => {
      console.log(this.selectedTeam);
      
    }, 1000);
  }

  getDisplay(team: teamSelector): string{
    let result = '';
    if(this.selectedTeam.id == 0 || this.selectedTeam.id == team.id){
      result = 'display: block;';
    } else {
      if(team.id == this.selectedTeam.id -1 || team.id == this.selectedTeam.id +1){
        result = 'display: block;';
      } else {
        result = 'display: none;';

        if(this.selectedTeam.id == 1){
          if(team.id == this.selectedTeam.id +2){
            result = 'display: block;';
          }
        } else {
          if(this.selectedTeam.id == this.listTeam.length){
            if(team.id == this.selectedTeam.id -2){
              result = 'display: block;';
            }
          }
        }
      }
    }

    return result;
  }
  
}
