import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { OverHeadComponent } from './components/over-head/over-head.component';
import { PageInicioComponent } from './pages/page-inicio/page-inicio.component';
import { PageElCentroComponent } from './pages/page-el-centro/page-el-centro.component';
import { PageEquipoDocenteComponent } from './pages/page-equipo-docente/page-equipo-docente.component';
import { PageIformacionCicloComponent } from './pages/page-iformacion-ciclo/page-iformacion-ciclo.component';
import { PagePlanEstudiosComponent } from './pages/page-plan-estudios/page-plan-estudios.component';
import { PageSalidasProfesionalesComponent } from './pages/page-salidas-profesionales/page-salidas-profesionales.component';
import { PageQuienesSomosComponent } from './pages/page-quienes-somos/page-quienes-somos.component';
import { PagePruebaDeAccesoComponent } from './pages/page-prueba-de-acceso/page-prueba-de-acceso.component';
import { PageInformacionCicloComponent } from './pages/page-informacion-ciclo/page-informacion-ciclo.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './components/footer/footer.component';
import { PageInicioYellowComponent } from './pages/page-inicio-yellow/page-inicio-yellow.component';
import { PageDocumentacionComponent } from './pages/page-documentacion/page-documentacion.component';
import { PageInicioYellowTwoComponent } from './pages/page-inicio-yellow-two/page-inicio-yellow-two.component';
import { PageInicioYellowThreeComponent } from './pages/page-inicio-yellow-three/page-inicio-yellow-three.component';
import { EquipoDocenteTwoComponent } from './pages/equipo-docente-two/equipo-docente-two.component';
import { TripleTitlePipe } from './pipes/triple-title.pipe';
import { TitleDecorComponent } from './components/title-decor/title-decor.component';
import { HomePageComponent } from './final/home-page/home-page.component';
import { ReCaptchaModule } from 'angular-recaptcha3';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EquipoDocentePageComponent } from './final/equipo-docente-page/equipo-docente-page.component';
import { QuienesSomosPageComponent } from './final/quienes-somos-page/quienes-somos-page.component';
import { ElCentroPageComponent } from './final/el-centro-page/el-centro-page.component';
import { ElCicloPageComponent } from './final/el-ciclo-page/el-ciclo-page.component';
import { PruebaAccesoPageComponent } from './final/prueba-acceso-page/prueba-acceso-page.component';

import * as CanvasJSAngularChart from '../assets/canvasjs/canvasjs.angular.component';
import { RecaptchaComponent } from './pages/recaptcha/recaptcha.component';
import { CondicionesDeUsoComponent } from './legal/condiciones-de-uso/condiciones-de-uso.component';
import { PoliticaCookiesComponent } from './legal/politica-cookies/politica-cookies.component';
import { PoliticaPrivacidadComponent } from './legal/politica-privacidad/politica-privacidad.component';
import { ClausulasLegalesComponent } from './legal/clausulas-legales/clausulas-legales.component';
import { CookiesScreenComponent } from './components/cookies-screen/cookies-screen.component';
import { TablonDocumentosComponent } from './final/tablon-documentos/tablon-documentos.component';
var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;

@NgModule({
  declarations: [
    AppComponent,
    CanvasJSChart,
    MainMenuComponent,
    OverHeadComponent,
    PageInicioComponent,
    PageElCentroComponent,
    PageEquipoDocenteComponent,
    PageIformacionCicloComponent,
    PagePlanEstudiosComponent,
    PageSalidasProfesionalesComponent,
    PageQuienesSomosComponent,
    PagePruebaDeAccesoComponent,
    PageInformacionCicloComponent,
    FooterComponent,
    PageInicioYellowComponent,
    PageDocumentacionComponent,
    PageInicioYellowTwoComponent,
    PageInicioYellowThreeComponent,
    EquipoDocenteTwoComponent,
    TripleTitlePipe,
    TitleDecorComponent,
    HomePageComponent,
    EquipoDocentePageComponent,
    QuienesSomosPageComponent,
    ElCentroPageComponent,
    ElCicloPageComponent,
    PruebaAccesoPageComponent,
    RecaptchaComponent,
    CondicionesDeUsoComponent,
    PoliticaCookiesComponent,
    PoliticaPrivacidadComponent,
    ClausulasLegalesComponent,
    CookiesScreenComponent,
    TablonDocumentosComponent
  ],
  imports: [
    FormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReCaptchaModule.forRoot({
      invisible: {
          sitekey: '6LcQs4UlAAAAAD--CuKLCpdyVFGUJIztvQ9zl4Hp', 
      },
      normal: {
          sitekey: '6LcQs4UlAAAAAD--CuKLCpdyVFGUJIztvQ9zl4Hp', 
      },
      language: 'es'
  })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
