import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-salidas-profesionales',
  templateUrl: './page-salidas-profesionales.component.html',
  styleUrls: ['./page-salidas-profesionales.component.scss']
})
export class PageSalidasProfesionalesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
