import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router,Event as NavigationEvent } from '@angular/router';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  public ispage2 = false;

  public isPublic = true;

  
  public toggleActive = 1;

  constructor(private router: Router) { 

  }

  ngOnInit(): void {
    
    //console.log(this.router.url);
    
    this.router.events.subscribe( (event: NavigationEvent) => {
      if(event instanceof NavigationStart) {
        console.log(event.url);
        
        if(event.url == '/' || event.url == '/politica-cookies'){
          this.isPublic = true;
        }
      }
    });
   
  }


  toggleMenu() {
    this.toggleActive *= -1;
  }

  hideToggleMenu() {
    this.toggleActive = 1;
  }


  
}
