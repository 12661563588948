import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-iformacion-ciclo',
  templateUrl: './page-iformacion-ciclo.component.html',
  styleUrls: ['./page-iformacion-ciclo.component.scss']
})
export class PageIformacionCicloComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
