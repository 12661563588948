<div class="container">
    <div class="row">
        <div class="col">
            <h1>Quiénes somos</h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p>MAB DANCE CENTER nace con el objetivo de ofrecer un nuevo concepto de escuela de danza, ¡queremos que esta sea tu casa! Nuestra filosofía de trabajo pasa por enseñar mucho más que pasos, queremos transmitir e inculcar respeto, trabajo, auto-exigencia
                y constancia a través de la motivación, el trabajo en equipo, el amor por lo que hacemos y todo lo que ya de por sí nos aporta la música y la danza ¡que no es poco!
            </p>

        </div>
    </div>
    <div class="row">
        <div class="col">
            <p>En MAB DANCE CENTER nos especializamos en danzas urbanas además de ofrecer un amplio abanico de disciplinas y posibilidades, pero hay algo en común en todas ellas: nos centramos en cada uno de nuestros alumnos, nuestro fuerte es el trato personalizado,
                ¡trabajamos que cada día para que seas la mejor versión de ti mismo!
            </p>
        </div>
    </div>
</div>

<div class="container-fluid footer-separation">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1>MARYA AGUILAR</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">
                <img src="/assets/images/marya_aguilar_1.jpg" />
            </div>
            <div class="col-md-7">
                <p>El alma y la cabecita pensante que lleva el timón de este barco.</p>
                <p>Toma su primera clase con tan solo 3 años y desde entonces su vínculo con la danza no tiene fin. Lo que empezó siendo una hora a la semana de felicidad en forma de música y movimiento se terminó convirtiendo en su refugio, su pasión, en
                    aquello que es y quería ser, lo que derivó en la mejor de todas las locuras, el proyecto más soñado y deseado: MAB Dance Center.</p>

            </div>
            <div class="col-md-12">
                <p>Profesora de danzas urbanas más de la mitad de su vida (literalmente) pasando por muchas escuelas de danza de Tarragona y Barcelona y bailarina de la misma disciplina en múltiples escenarios y formatos. </p>
                <p>Se formó desde muy pequeña en clásico, jazz y contemporáneo y al toparse con las danzas urbanas supo que allí era. Se especializó en Hip-Hop, Dancehall, Sexy Style y Técnica de Heels, Comercial Dance en las mejores escuelas a nivel estatal
                    y a nivel internacional en París, Los Ángeles, Nueva York y Toulouse.</p>
            </div>
        </div>
    </div>
</div>