import { Component, OnInit } from '@angular/core';
import { ReCaptchaService } from 'angular-recaptcha3';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  public errorEmptyInputsContacto = false;
  public errorEmptyInputsVisita = false;

  public checkContactLegal = false;
  public checkVisitaLegal = false;

  public vClass = 'error'; // success | error
  public vShow = false;
  public vMessage = '';

  public formValuesContacto: {
    name: string;
    phone: string;
    email: string;
    lastname: string;
  } = {
    name: '',
    phone: '',
    email: '',
    lastname: '',
  };

  public showSuccess = false;

  constructor(
    private recaptchaService: ReCaptchaService,
    private _http: HttpClient
  ) {}

  ngOnInit() {
    window.scroll(0, 0);
  }

  clearAlert() {
    this.vClass = 'error';
    this.vMessage = '';
    this.vShow = false;
  }

  sendFormContact() {
    if (this.validateFormContacto()) {
      this.errorEmptyInputsContacto = false;
      this.recaptchaService.execute({ action: 'forminfo' }).then((token) => {
        this.finalSend(this.formValuesContacto, token, 'forminfo');
      });
    } else {
      this.errorEmptyInputsContacto = true;
    }

    /*if( this.validateFormContacto() ){
      this.errorEmptyInputsContacto = false;
      this.finalSend(this.formValuesContacto,'token','forminfo');
    }else {
      this.errorEmptyInputsContacto = true;
    }*/
  }

  validateFormContacto(): boolean {
    let result = false;
    if (
      this.formValuesContacto.email != '' &&
      this.formValuesContacto.lastname != '' &&
      this.formValuesContacto.name != '' &&
      this.formValuesContacto.phone != ''
    ) {
      if (this.checkContactLegal) {
        result = true;
      }
    }

    return result;
  }

  closeSuccessMessage() {
    this.showSuccess = false;
  }

  finalSend(params: any, token: any, action: any) {
    let finalParams: any = {};
    finalParams['token'] = token;
    finalParams['params'] = params;
    finalParams['action'] = action;

    let paramsHttp = new HttpParams();
    paramsHttp = paramsHttp.append('params', JSON.stringify(finalParams));
    let headers = new HttpHeaders();

    let resultError = true;
    let resultMessage = 'Ocurrió un error, inténtelo más tarde.';
    this._http
      .request('post', 'https://contacto.mabdancecenter.com/form_submit.php', {
        body: paramsHttp,
        headers: headers,
        withCredentials: true,
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (typeof response !== 'undefined') {
            if (typeof response.error !== 'undefined') {
              if (!response.error) {
                resultError = false;
                resultMessage = 'Mensaje enviado correctamente.';
                this.formValuesContacto = {
                  name: '',
                  phone: '',
                  email: '',
                  lastname: '',
                };
                this.showSuccess = true;
              } else {
                if (typeof response.error_name !== 'undefined') {
                  switch (response.error_name) {
                    case 'empty_params':
                      resultMessage = 'Se deben rellenar todos los campos.';

                      break;
                    case 'not_valid_params':
                    case 'empty_params_system':
                    case 'empty_form_type':
                    case 'not_sent':
                      resultMessage = 'Ocurrió un error, inténtelo más tarde.';

                      break;
                    case 'spam':
                      resultMessage = 'Captcha no correcto.';
                      break;
                  }
                }
              }
            }
          }
        },
        (res) => {
          console.log(res);
        },
        () => {
          if (resultError) {
            this.vClass = 'error';
          } else {
            this.vClass = 'success';
          }

          this.vMessage = resultMessage;
          this.vShow = true;

          setTimeout(() => {
            this.clearAlert();
          }, 6000);
        }
      );
  }
}
