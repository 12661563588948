<div class="container-fluid body-page back-paper-black">
    <div class="decor-page-left"></div>
    <div class="row">        
        <div class="col-md-12 d-none d-lg-block" style="padding-top: 70px;"> 
            <app-title-decor [firstLine]="'El centro'" [fontSize]="5" [fontSizeMetric]="'vw'" [numberDecors]="2"></app-title-decor>
        </div>
        <div class="col-md-12 d-lg-none" style="padding-top: 70px;"> 
            <app-title-decor [firstLine]="'El centro'" [fontSize]="10" [fontSizeMetric]="'vw'" [numberDecors]="2"></app-title-decor>
        </div>
    </div>
    <div class="limit-widht">
        <div class="row">
            <div class="col-lg-12 content-desc"> 
                <p>Estamos ubicados en el distrito de <b>Sant Andreu</b> de <b>Barcelona</b>, cerca del metro de Sant Andreu y Fabra i Puig (L1) y de la estación de tren de Sant Andreu. En <b>el corazón del barrio</b> disfrutamos de unas instalaciones con <b>3 salas insonorizadas</b> de 80m, 70m y 30m, adecuadas para la realización del ciclo, 2 vestuarios, 2 baños, zona de descanso, recepción, sala de profesores,..</p>
                <p>Además disponemos de todo tipo de <b>material didáctico</b>, pantallas para la visualización de <b>material videográfico</b> y lo que podáis imaginar necesario para que nuestros alumnos disfruten de una <b>experiencia completa</b>. A pesar de el gran tamaño de nuestras instalaciones, seguiremos la <b>filosofía</b> de centro evitando las masificaciones para poder ofrecer a cada uno de los estudiantes una <b>formación personalizada y única</b>, centrada en alcanzar los <b>objetivos</b> que le lleven al camino profesional que desea con la garantía curricular del Departament d'Ensenyament de la Generalitat de Catalunya.</p>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-md-6 content-gallery-dual-left">
                <img src="/assets/images/centro/centro_d_1.png" class="left" />
                <img src="/assets/images/centro/centro_d_3.png" class="left" />
            </div>
            <div class="col-md-6 content-gallery-dual-right">
                <img src="/assets/images/centro/centro_d_2.png" class="right" />
                <img src="/assets/images/centro/centro_d_4.png" class="right" />
            </div>
        </div>-->



        <div class="content-gallery-2"> 
            <div class="line-img-1">
                <div class="l_i_1">
                    <img src="/assets/images/centro/i_centro_12.jpg" />
                </div>
                <div class="l_i_2">
                    <img src="/assets/images/centro/i_centro_11.jpg" />
                </div>
                <div class="l_i_3">
                    <img src="/assets/images/centro/i_centro_13.jpg" />
                </div>
            </div> 
            <div class="line-img-2">
                <div class="l_i_1">
                    <img src="/assets/images/centro/i_centro_9.jpg" />
                </div>
                <div class="l_i_2">
                    <img src="/assets/images/centro/i_centro_5.jpg" />
                </div>
               
            </div> 
            <div class="line-img-1">
                <div class="l_i_1">
                    <img src="/assets/images/centro/i_centro_6.jpg" />
                </div>
                <div class="l_i_2">
                    <img src="/assets/images/centro/i_centro_2.jpg" />
                </div>
                <div class="l_i_3">
                    <img src="/assets/images/centro/i_centro_3.jpg" />
                </div>
            </div> 
            
            <div class="line-img-3">
                <div class="l_i_1">
                    <img src="/assets/images/centro/i_centro_8.jpg" />
                </div>
                <div class="l_i_2">
                    <img src="/assets/images/centro/i_centro_7.jpg" />
                </div>
            </div> 
            <div class="line-img-1">
                <div class="l_i_1">
                    <img src="/assets/images/centro/i_centro_1.jpg" />
                </div>
                <div class="l_i_2">
                    <img src="/assets/images/centro/i_centro_4.jpg" />
                </div>
                <div class="l_i_3">
                    <img src="/assets/images/centro/i_centro_10.jpg" />
                </div>
            </div> 
        </div>

        <div class="row mb-5">
            <div class="col-lg-12 content-img">  
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d373.8890720870283!2d2.184831888619092!3d41.43677886588561!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4bcdf4263da75%3A0xd616d569e15f7f16!2sMAB%20Dance%20Center!5e0!3m2!1ses!2ses!4v1681986826266!5m2!1ses!2ses" style="border:0;" allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>       
                <img src="/assets/images/decors/diagonal_grey_a1.png" class="decor-diagonal-grey-1" />
            </div>
        </div>

        <!--<div class="row">
            <div class="col-md-12 content-gallery">  
                <img src="/assets/images/centro/centro_6.jpg" />
                <img src="/assets/images/centro/centro_2.jpg" />
                <img src="/assets/images/centro/centro_3.jpg" />
                <img class="active" src="/assets/images/centro/centro_9.jpg" />
                <img src="/assets/images/centro/centro_5.jpg" />
                <img src="/assets/images/centro/centro_1.jpg" />
                <img src="/assets/images/centro/centro_7.jpg" />
                <img src="/assets/images/centro/centro_8.jpg" />
                <img src="/assets/images/centro/centro_4.jpg" />

            </div>
        </div>-->


        
    
    
</div>

    <!--<div class="content-gallery">  
        <img src="/assets/images/centro/centro_6.jpg" />
        <img src="/assets/images/centro/centro_2.jpg" />
        <img src="/assets/images/centro/centro_3.jpg" />
        <img class="active" src="/assets/images/centro/centro_9.jpg" />
        <img src="/assets/images/centro/centro_5.jpg" />
        <img src="/assets/images/centro/centro_1.jpg" />
        <img src="/assets/images/centro/centro_7.jpg" />
        <img src="/assets/images/centro/centro_8.jpg" />
        <img src="/assets/images/centro/centro_4.jpg" />
    </div>-->

    