<div class="spacer"></div>
<footer>    
    <div class="container">
        <div class="row footer-columns">
            <div class="col-md-4">
                <img src="/assets/images/MAB-LOGO-small_yellow.png">
            </div>
            
            <div class="col-md-4">
                <div>
                    <a [routerLink]="['/tablon-doc-oficiales-generalitat']">Documentos oficiales Generalitat</a>
                </div>
                <div>
                    <a [routerLink]="['/politica-privacidad-redes-sociales']">Política de privacidad y redes sociales</a>
                </div>
                
            </div>
            <div class="col-md-4">
                
                <div>
                    <a [routerLink]="['/politica-cookies']">Política de cookies</a>
                </div>
                
                <div>
                    <a [routerLink]="['/clausulas-legales']">Cláusulas legales</a>
                </div>
                <div>
                    <a [routerLink]="['/condiciones-de-uso']">Condiciones de uso</a>
                </div>
            </div>
            
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="footer-text">
                    Escola Autoritzada pel Departament d’Ensenyament de la Generalitat de Catalunya. Codi de centre: 08078191. Copyright © 2023 Mab Dance Center.
                </div>
            </div>
        </div>
    </div>
</footer>