import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router,Event as NavigationEvent } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public isPublic = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    /*this.router.events.subscribe( (event: NavigationEvent) => {
      if(event instanceof NavigationStart) {
        console.log(event.url);
        
        if(event.url == '/' || event.url == '/politica-cookies'){
          this.isPublic = true;
        }
      }
    });*/
  }

}
