<div class="container-fluid body-page back-paper-black">
    <div class="decor-page-left"></div>
    <div class="row">    
        <div class="col-md-12 d-none d-lg-block" style="padding-top: 70px;"> 
            <app-title-decor [firstLine]="'Prueba de acceso'" [fontSize]="5" [fontSizeMetric]="'vw'" [numberDecors]="2"></app-title-decor>
        </div>
        <div class="col-md-12 d-lg-none" style="padding-top: 70px;"> 
            <app-title-decor [firstLine]="'Prueba'" [secondLine]="'de acceso'" [fontSize]="10" [fontSizeMetric]="'vw'" [numberDecors]="2"></app-title-decor>
        </div>
    </div>
    <div class="limit-widht">
        <div class="row">           
            <div class="col-md-12 content-desc"> 
                <p>Para acceder a esta <b>formación</b> y posterior <b>titulación</b> propia de la Generalitat de Catalunya es necesario haber superado con éxito los <b>Estudios Secundarios Obligatorios</b> y hay que realizar una <b>prueba de acceso</b> que tendrá lugar el próximo <b>16 de junio</b> en MAB Dance Center y tendrá una duración máxima de 90 minutos. </p>
                <p> La <b>prueba</b> constará de una clase práctica para valorar las <b>capacidades y conocimentos</b> previos de las danzas urbanas. Se impartirá una clase que reunirá diferentes estilos de <b>danzas urbanas</b> y también se valorará el conocimiento del movimiento propio o habilidades de freestyle. La <b>prueba</b> consistirá en un calentamiento dirigido, una combinación de pasos y enlaces de diferentes estilos de danza urbana en el que podremos pedir <b>freestyle</b> al final de la ejecución y para acabar los estiramientos finales. </p>
                
                <img src="/assets/images/decors/diagonal_grey_a1.png" class="decor-diagonal-grey-1" />
              
            </div>
        </div>
    </div>  
    <div class="row decor-image-break-1">           
        
        <div class="break-top">
            <img src="/assets/images/horizontal_break_page_generic_a3.png">
        </div>
        <div class="col-md-12"> 
            <p><i>MAB te ofrece un pack de 4 o 8 clases preparatorias para la prueba de acceso para que esté todo bajo control. ¡Pídenos info!</i></p>      
        </div>
        <div class="break-bottom">
            <img src="/assets/images/horizontal_break_page_generic_invert_a3.png">
        </div>
            
       
    </div>
    <div class="limit-widht">
        <div class="row">           
            <div class="col-md-12 content-desc"> 
               <p style="text-align: center;"> Los <b>criterios de evaluación</b> son los indicados por el Departament d'Educació de la Generalitat de Catalunya y son los siguientes: </p>
            </div>
        </div>
    </div>   
    <div class="limit-widht chart-container">
        <div class="row">  
            <div class="col-md-12 d-none d-lg-block content-desc" > 
                <canvasjs-chart [options]="chartOptions"></canvasjs-chart>  
                <img src="/assets/images/decors/star_a1.png" class="decor-star-1" />
            </div>
            <div class="col-md-12 d-lg-none content-desc" > 
                <canvasjs-chart [options]="chartOptionsResponsive"></canvasjs-chart>  
            </div>
        </div>
    </div>
    <div class="limit-widht">
        <div class="row">           
            <div class="col-md-12 content-desc"> 
                <p>Esta <b>prueba</b> será puntuada del 1 al 10 realizando la media ponderada. Para <b>aprobar</b> habrá que puntuar como mínimo con un 5. Esta sesión será evaluada por una <b>comisión de docentes expertos</b> en danza urbana, la <b>dirección del centro</b> y el <b>Inspector</b> de Zona del Departament d'Educación de la Generalitat de Catalunya.</p>
                <p class="last-wait">¡TE PREPARAMOS!</p>
            </div>
        </div>
    </div> 
    
</div>
<div class="bottom-image-decor">
    <img src="/assets/images/bottom_image/bottom_img_8.jpg" />
</div>
