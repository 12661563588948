import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router,Event as NavigationEvent } from '@angular/router';

@Component({
  selector: 'app-cookies-screen',
  templateUrl: './cookies-screen.component.html',
  styleUrls: ['./cookies-screen.component.scss']
})
export class CookiesScreenComponent implements OnInit {

  public activeShadow = true;
  private acceptedCookie = false;

  constructor(private router: Router) { }

  ngOnInit(): void {

    let readCookie = this.getCookie('mab_formacion_cookie_policy_accept');
    console.log('aaa',readCookie);
    if(readCookie == 'true'){
      this.acceptedCookie = true;
      this.activeShadow = false;
    }

    this.router.events.subscribe( (event: NavigationEvent) => {
      if(event instanceof NavigationStart) {
        if(!this.acceptedCookie){
          if(event.url == '/politica-cookies'){
            this.activeShadow = false;
          } else {
            this.activeShadow = true;
          }
        }  
      }
    });
  }

  acceptCookies(){
    this.activeShadow = false;
    this.acceptedCookie = true;
    this.setCookie('mab_formacion_cookie_policy_accept','true',999,'');
  }

  private getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
            return c.substring(cookieName.length, c.length);
        }
    }
    return '';
  }
  
  private deleteCookie(name:string) {
      this.setCookie(name, '', -1);
  }
  
  private setCookie(name: string, value: string, expireDays: number, path: string = '') {
      let d:Date = new Date();
      d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
      let expires:string = `expires=${d.toUTCString()}`;
      let cpath:string = path ? `; path=${path}` : '';
      document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

}
