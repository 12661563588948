<div class="container">
    <div class="row">
        <div class="col">
            <h1>Cláusulas legales</h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h2>CLÁUSULA INFORMATIVA RGPD Y LSSI-CE</h2>
            <p>De conformidad con lo establecido en la normativa vigente en Protección de Datos de Carácter Personal, le informamos que sus datos serán incorporados al sistema de tratamiento titularidad de MARIA AGUILAR BOSQUE con CIF 47755465J y domicilio social sito en CARRER MALALTS 79 08030, BARCELONA, con la finalidad de poder atender los compromisos derivados de la relación que mantenemos con usted. En cumplimiento con la normativa vigente, MARIA AGUILAR BOSQUE informa que los datos serán conservados durante el plazo estrictamente necesario para cumplir con los preceptos mencionados con anterioridad.</p>
            <p>Asimismo le informamos que el tratamiento indicado en el párrafo anterior tratamos sus datos conforme a la existencia de su consentimiento.</p>
            <p>MARIA AGUILAR BOSQUE informa que procederá a tratar los datos de manera lícita, leal, transparente, adecuada, pertinente, limitada, exacta y actualizada. Es por ello que MARIA AGUILAR BOSQUE se compromete a adoptar todas las medidas razonables para que estos se supriman o rectifiquen sin dilación cuando sean inexactos.</p>
            <p>De acuerdo con los derechos que le confiere el la normativa vigente en protección de datos podrá ejercer los derechos de acceso, rectificación, limitación de tratamiento, supresión, portabilidad y oposición al tratamiento de sus datos de carácter personal así como del consentimiento prestado para el tratamiento de los mismos, dirigiendo su petición a la dirección postal indicada más arriba o al correo electrónico INFO@MABDANCECENTER.</p>
            <p>Asimismo y de acuerdo con lo establecido en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, en su artículo 21, solicitamos también su consentimiento expreso para enviarle publicidad de nuestros productos o promociones que consideremos puedan ser de su interés, por correo electrónico o por cualquier otro medio de comunicación electrónica equivalente.</p>
            <p>□ He leído y acepto recibir publicidad o información promocional de MARIA AGUILAR BOSQUE.</p>
            <p>□ He leído y no acepto recibir publicidad o información promocional de MARIA AGUILAR BOSQUE.</p>
            <p>Le informamos que podrá revocar en cualquier momento el consentimiento prestado a la recepción de comunicaciones comerciales enviando un e-mail a la dirección de correo electrónico:</p>
            <p>
                INFO@MABDANCECENTER.<br />
                Nombre y apellidos o Razón social:<br />
                DNI o CIF:<br />
                Firma del interesado.<br />
            </p>           
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h2>CLÁUSULA PARA INTRODUCIR EN EL FORMULARIO WEB CON PUBLICIDAD</h2>
            <p>De conformidad con lo establecido en la normativa vigente en Protección de Datos de Carácter Personal, le informamos que sus datos serán incorporados al sistema de tratamiento titularidad de MARIA AGUILAR BOSQUE con CIF 47755465J y domicilio social sito en CARRER MALALTS 79 08030, BARCELONA, con la finalidad de atender sus consultas. En cumplimiento con la normativa vigente, MARIA AGUILAR BOSQUE informa que los datos serán conservados durante el plazo estrictamente necesario para cumplir con los preceptos mencionados con anterioridad.</p>
            <p>Asimismo le informamos que el tratamiento indicado en el párrafo anterior tratamos sus datos conforme a la existencia de su consentimiento.</p>
            <p>MARIA AGUILAR BOSQUE informa que procederá a tratar los datos de manera lícita, leal, transparente, adecuada, pertinente, limitada, exacta y actualizada. Es por ello que MARIA AGUILAR BOSQUE se compromete a adoptar todas las medidas razonables para que estos se supriman o rectifiquen sin dilación cuando sean inexactos.</p>
            <p>De acuerdo con los derechos que le confiere el la normativa vigente en protección de datos podrá ejercer los derechos de acceso, rectificación, limitación de tratamiento, supresión, portabilidad y oposición al tratamiento de sus datos de carácter personal así como del consentimiento prestado para el tratamiento de los mismos, dirigiendo su petición a la dirección postal indicada más arriba o al correo electrónico INFO@MABDANCECENTER.</p>
            <p>Le informamos que podrá revocar en cualquier momento el consentimiento prestado a la recepción de comunicaciones comerciales enviando un e-mail a la dirección de correo electrónico: INFO@MABDANCECENTER.</p>
            <p>Podrá dirigirse a la Autoridad de Control competente para presentar la reclamación que considere oportuna.</p>
            <p>El envío de estos datos implica la aceptación de esta cláusula.</p>

        </div>
    </div>

</div>

<div class="container-fluid footer-separation">

</div>