import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-inicio-yellow-two',
  templateUrl: './page-inicio-yellow-two.component.html',
  styleUrls: ['./page-inicio-yellow-two.component.scss']
})
export class PageInicioYellowTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
