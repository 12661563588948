<div class="container-fluid body-page back-paper-black">
    <div class="decor-page-left"></div>
    <div class="row">        
        <div class="col-md-12 d-none d-lg-block" style="padding-top: 70px;"> 
            <app-title-decor [firstLine]="'equipo docente'" [fontSize]="5" [fontSizeMetric]="'vw'" [numberDecors]="2"></app-title-decor>
        </div>
        <div class="col-md-12 d-lg-none" style="padding-top: 70px;"> 
            <app-title-decor [firstLine]="'equipo'" [secondLine]="'docente'" [fontSize]="10" [fontSizeMetric]="'vw'" [numberDecors]="2"></app-title-decor>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12"> 
            <div class="team-selector-container" *ngFor="let team of listTeam" [ngClass]="{'selected': team.id == selectedTeam.id}" [style]="getDisplay(team)">
                <div class="team-selector">
                    <img class="default" (click)="selectTeam(team)" [src]="'/assets/images/equipo_docente/'+team.imageDefault" />
                    <img class="selected" (click)="selectTeam(team)" [src]="'/assets/images/equipo_docente/'+team.imageSelected" />
                    <img class="mobile" [src]="'/assets/images/equipo_docente/'+team.imageSelected" />
                </div>
                <div class="team-selector-name" [innerHTML]="team.name">
                </div>
                <div class="team-selector-active-info">
                    <div class="team-selector-active-info-name" [innerHTML]="team.name">
                    </div>
                    <span class="close-team" (click)="closeSelectedTeam()">
                        <i class="fa fa-times-circle" aria-hidden="true"></i>
                    </span>
                    <div class="team-selector-active-info-desc" [innerHTML]="team.desc">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="bottom-image-decor">
    <img src="/assets/images/bottom_image/bottom_img_10.jpg" />
</div>


