<div class="container">
    <div class="row">
        <div class="col">
            <h1>Documentos oficiales de la Generalitat</h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p>Aquí se mostraría un tabrón con todos los documentos</p>
        </div>
    </div>

</div>

<div class="container-fluid footer-separation">

</div>