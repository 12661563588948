<div class="container-fluid body-page back-paper-black">
    <div class="decor-page-left"></div>
    <div class="row">        
        <div class="col-md-12 d-none d-lg-block" style="padding-top: 70px;"> 
            <app-title-decor [firstLine]="'Tablón documentos'" [fontSize]="5" [fontSizeMetric]="'vw'" [numberDecors]="2"></app-title-decor>
        </div>
        <div class="col-md-12 d-lg-none" style="padding-top: 70px;"> 
            <app-title-decor [firstLine]="'Tablón'" [secondLine]="'documentos'" [fontSize]="8" [fontSizeMetric]="'vw'" [numberDecors]="2"></app-title-decor>
        </div>
    </div>

    <div class="limit-widht">
        <div class="row">
            <div class="col-md-12"> 
                <div class="docs-list">
                    <h3 class="not-files" *ngIf="!listDocs.length">
                        Actualmente no hay ningún documento disponible.
                    </h3>
                    <a class="doc-elem" *ngFor="let doc of listDocs" target="_blank" [href]="urlBase+doc.filename">
                        <div class="img-content">
                            <img src="/assets/images/doc_icon_a1.png">
                        </div>
                        <div class="name">
                            {{doc.name}}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="bottom-image-decor">
    <img src="/assets/images/bottom_image/bottom_img_5.jpg" />
</div>


