<section class="home-main-section">
    <div class="container-fluid cstm-front">
        <iframe class="background-video" src="https://www.youtube.com/embed/KhEtqwEFsHU?controls=0&mute=1&autoplay=1&loop=1&playlist=KhEtqwEFsHU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <div class="background-video-shaow"></div>
        <div class="row">
            <div class="col-md-7 cstm-left">
                <div class="y-shape"></div>
                <div class="b-shape">
                    <span class="line-1">CICLO FORMATIVO</span>
                    <span class="line-2">GRADO MEDIO</span>
                    <span class="line-3">DANZAS URBANAS</span>
                </div>
            </div>
            <div class="col-md-5 cstm-right">
                <div class="y-shape">
                </div>
                <div class="b-shape">
                    <p>Nombre</p>
                    <p><input type="text"></p>
                    <p>Nombre</p>
                    <p><input type="text"></p>
                    <p>Nombre</p>
                    <p><input type="text"></p>
                    <div class="button-input">Enviar</div>
                </div>

            </div>
        </div>
    </div>
    <img class="decor-arrow-bottom-a" src="/assets/images/decors/triangle_c3.png">
    <img class="decor-arrow-bottom-b" src="/assets/images/decors/triangle_b1.png">
    <img class="decor-arrow-bottom-c" src="/assets/images/decors/triangle_a1.png">
    <img class="break-papper" src="/assets/images/break_papper_e1.png">
    <img class="break-papper-2" src="/assets/images/break_papper_f4.png">
</section>




<section class="home-third-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8">
                <img class="image-muestreo-1" src="/assets/images/muestreo_a1.jpg" />
                <div class="third-section-text">
                    <ul _ngcontent-sqr-c16="">
                        <li _ngcontent-sqr-c16="">Aprende con profesionales experimentados</li>
                        <li _ngcontent-sqr-c16="">Descubre todo tu potencial</li>
                        <li _ngcontent-sqr-c16="">Tareas siempre guiadas y asistidas</li>
                        <li _ngcontent-sqr-c16="">Buena distribución de los tiempos de aprendizaje</li>
                        <li _ngcontent-sqr-c16="">Instalaciones adecuadas para la actividad</li>
                    </ul>
                </div>

                <div class="third-section-text-yellow">
                    MAB Dance Center será la primera escuela de danza en Barcelona en impartir el Ciclo Formativo de Grado Medio de Danzas Urbanas, un título propio de la Generalitat de Catalunya.
                </div>
            </div>
            <div class="col-md-4">
                <h1 class="title-form">Recibe + info</h1>
                <div class="home-third-form">

                    <form>
                        <div class="mb-3">
                            <label for="inputName" class="form-label">Nombre</label>
                            <input type="text" class="form-control" id="inputName">
                        </div>
                        <div class="mb-3">
                            <label for="inputLastname" class="form-label">Apellidos</label>
                            <input type="text" class="form-control" id="inputLastname">
                        </div>
                        <div class="mb-3">
                            <label for="inputEmail" class="form-label">Email</label>
                            <input type="email" class="form-control" id="inputEmail">
                        </div>
                        <div class="mb-3">
                            <label for="inputPhone" class="form-label">Teléfono</label>
                            <input type="text" class="form-control" id="inputPhone">
                        </div>
                        <div class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="checkboxTerms">
                            <label class="form-check-label" for="checkboxTerms">He leído y comprendo la Política de Privacidad y los Términos de uso de la página web.</label>
                        </div>
                        <div class="send-button-container">
                            <button type="submit" class="send-button">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <img class="decor-circle-1" src="/assets/images/decors/circles_b1.png" />
    <img class="break-papper" src="/assets/images/break_papper_e1.png">
</section>


<section class="home-second-section">
    <div class="decor-page-left"></div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 home-second-text">
                <div class="text-a">
                    LA PRIMERA ESCUELA DE DANZA DE BARCELONA EN IMPARTIR EL CICLO DE GRADO MEDIO DE DANZAS URBANAS DE LA GENERALITAT DE CATALUNYA
                </div>
                <div class="text-b">
                    MAB es casa para todo aquel que ama la danza, sea de forma amateur o con proyección profesional y por ello durante estos años hemos estado celebrando la danza en múltiples formas: festivales, proyectos con valores, obra social, showcase de alumnos, colaboraciones
                    artísticas, shows, exhibiciones, workshops, videoclips con artistas como Tote King, Kaydy Cain, Vicco,..
                </div>
            </div>
            <div class="col-md-6">
                <div class="home-second-section-video">
                    <iframe src="https://www.youtube.com/embed/Y2jap4aCw74?controls=0&mute=1&autoplay=1&loop=1&playlist=Y2jap4aCw74" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
    <div class="second-sec-bottom"></div>
    <img class="decor-circle-1" src="/assets/images/decors/circles_b1.png" />
    <img class="decor-waves-1" src="/assets/images/decors/waves_a1.png" />
    <img class="decor-points-1" src="/assets/images/decors/points_a1.png" />
</section>
