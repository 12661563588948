<div class="container-fluid body-page back-paper-black">
    <div class="decor-page-left"></div>
    <div class="row">
        
            <ng-container>
                <div class="col-md-12 d-none d-lg-block" style="padding-top: 70px;"> 
                    <app-title-decor [firstLine]="'¿Quiénes somos?'" [fontSize]="5" [fontSizeMetric]="'vw'" [numberDecors]="2"></app-title-decor>
                </div>
            </ng-container>
            <ng-container>
                <div class="col-md-12 d-lg-none" style="padding-top: 70px;"> 
                    <app-title-decor [firstLine]="'¿Quiénes'" [secondLine]="'somos?'" [fontSize]="12" [fontSizeMetric]="'vw'" [numberDecors]="2"></app-title-decor>
                </div>
            </ng-container>
            
       
    </div>
    <div class="limit-widht">
        <div class="row">
            <div class="col-md-6 content-img">  
               
                    <img src="/assets/images/sus_iframe_b2.jpg" />         
                
            </div>
            <div class="col-md-6 content-desc d-none d-lg-block"> 
                <p><b>MAB DANCE CENTER</b> nace en 2014 de mano de su fundadora <b>Maria Aguilar</b>, quién tras una larga trayectoria en el mundo de las danzas urbanas decide crear un espacio que sea hogar para aquellos que quieran formarse en danza desde el crecimiento personal. Nuestra filosofía se basa en la personalización del aprendizaje: cada alumno/a necesita un proceso diferente para llegar a su <b>mejor versión</b> y nuestra labor es dar las herramientas adecuadas para ello.</p>
                <p>Creemos que la mejor forma de crecer es compitiendo contigo mismo y compartiendo con el resto. Así bajo la premisa de que la danza, y el arte en general, es algo para <b>disfrutar, compartir y vivir</b>, estas 4 paredes han sido casa para muchos bailarines. MAB DANCE CENTER es un centro de formación de <b>danzas urbanas</b> en el que habita una comunidad de 350 bailarines de disciplinas como el Hip Hop, Sexy Style, Dancehall, Afro, Comercial Dance, y todo aquello que está a la orden del día y relacionado con las danzas urbanas.</p>
            
            </div>

            <div class="col-md-6 d-lg-none content-desc"> 
                <p><b>MAB DANCE CENTER</b> nace en 2014 de mano de su fundadora <b>Maria Aguilar</b>, quién tras una larga trayectoria en el mundo de las danzas urbanas decide crear un espacio que sea hogar para aquellos que quieran formarse en danza desde el crecimiento personal. Nuestra filosofía se basa en la personalización del aprendizaje: cada alumno/a necesita un proceso diferente para llegar a su <b>mejor versión</b> y nuestra labor es dar las herramientas adecuadas para ello.</p>
            </div>
            <div class="col-md-12 d-lg-none content-desc"> 
                <p>Creemos que la mejor forma de crecer es compitiendo contigo mismo y compartiendo con el resto. Así bajo la premisa de que la danza, y el arte en general, es algo para <b>disfrutar, compartir y vivir</b>, estas 4 paredes han sido casa para muchos bailarines. MAB DANCE CENTER es un centro de formación de <b>danzas urbanas</b> en el que habita una comunidad de 350 bailarines de disciplinas como el Hip Hop, Sexy Style, Dancehall, Afro, Comercial Dance, y todo aquello que está a la orden del día y relacionado con las danzas urbanas.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 content-desc"> 
                <h3>¿Por qué?</h3>
                <p><b>MAB</b> es casa para todo aquel que <b>ama la danza</b>, sea de forma amateur o con proyección profesional, por ello durante estos años hemos estado celebrando la danza en múltiples formatos: festivales, proyectos con valores, obra social, showcase de alumnos, colaboraciones artísticas, shows, exhibiciones, workshops, videoclips con artistas como Tote King, Kaydy Cain, Vicco,.. e incluso <b>giras con artistas</b> como Rels B. Pero cuando uno de nuestros alumnos decide llevar la danza al siguiente nivel y dedicarse a ella profesionalmente nos encontramos sin un camino recto que seguir, una <b>formación profesional oficial</b>.</p>
                <p><i>"Por ello decidimos lanzarnos y ser la primera escuela de danza de Barcelona en impartir el Ciclo de Grado Medio de Danzas Urbanas de la Generalitat de Catalunya"</i></p>
                <img src="/assets/images/decors/star_a1.png" class="decor-star-1" />
                <img src="/assets/images/decors/diagonal_grey_a1.png" class="decor-diagonal-grey-1" />
                
            </div>
        </div>
    </div>
    
</div>
<div class="bottom-image-decor">
    <img src="/assets/images/bottom_image/bottom_img_3.jpg" />
</div>