<div class="container-fluid container-main-menu">
    <div class="row">
        <div class="col-md-12">
            <div class="main-menu" id="main-menu" [ngClass]="{'responsive': toggleActive == -1}">
                <a class="logo-button" style="padding-right: 12px !important;" [routerLink]="['/']"><img src="/assets/images/MAB-LOGO-small_yellow.png"></a>
                
                          
                    <a [routerLink]="['/inicio']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" class="home-btn-responsive" (click)="hideToggleMenu()">INICIO</a>
                    <a [routerLink]="['/el-centro']" [routerLinkActive]="['active']" (click)="hideToggleMenu()">EL CENTRO</a>
                    <a [routerLink]="['/equipo-docente']" [routerLinkActive]="['active']" (click)="hideToggleMenu()">EQUIPO DOCENTE</a>
                    <a [routerLink]="['/el-ciclo']" [routerLinkActive]="['active']" (click)="hideToggleMenu()">EL CICLO</a>
                    <!--<a [routerLink]="['/plan-estudios']" [routerLinkActive]="['active']">PLAN DE ESTUDIOS</a>-->
                    <a [routerLink]="['/prueba-acceso']" [routerLinkActive]="['active']" (click)="hideToggleMenu()">PRUEBA ACCESO</a>
                    <a [routerLink]="['/quienes-somos']" [routerLinkActive]="['active']" (click)="hideToggleMenu()">¿QUIÉNES SOMOS?</a>
                    <ng-container *ngIf="!ispage2">   
                        <a [routerLink]="['/inicio']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" class="home-btn-no-responsive">INICIO</a>
                    </ng-container>
                    <ng-container *ngIf="ispage2">          
                        <a [routerLink]="['/two']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" class="home-btn-no-responsive">INICIO</a>
                    </ng-container>  
                    <a href="javascript:void(0);" class="icon" (click)="toggleMenu()">
                        <i class="fa fa-bars"></i>
                    </a>
               
            </div>
        </div>
    </div>

</div>