import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-el-ciclo-page',
  templateUrl: './el-ciclo-page.component.html',
  styleUrls: ['./el-ciclo-page.component.scss']
})
export class ElCicloPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
