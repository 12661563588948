<div class="container">
    <div class="row">
        <div class="col">
            <h1>Salidas profesionales</h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p>El mundo laboral cada vez es más dinámico y los perfiles profesionales y las ofertas de trabajo van en consonancia. Para ser competitivo en el mercado de trabajo es importante tener en cuenta lo que están demandando las empresas, estar al
                tanto de los nuevos campos profesionales, ser consciente de las necesidades actuales y desarrollar competencias que agreguen valor al perfil profesional.</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-7">
            <p>El mundo laboral cada vez es más dinámico y los perfiles profesionales y las ofertas de trabajo van en consonancia. Para ser competitivo en el mercado de trabajo es importante tener en cuenta lo que están demandando las empresas, estar al
                tanto de los nuevos campos profesionales, ser consciente de las necesidades actuales y desarrollar competencias que agreguen profesional.</p>
        </div>
        <div class="col-md-5">
            <img src="/assets/images/mab_red_dark_bck_1.PNG">
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p>El mundo laboral cada vez es más dinámico y los perfiles profesionales y las ofertas de trabajo van en consonancia. Para ser competitivo en el mercado de trabajo es importante tener en cuenta lo que están demandando las empresas, estar al
                tanto de los nuevos campos profesionales, ser consciente de las necesidades actuales y desarrollar competencias que agreguen valor al perfil profesional.</p>
        </div>
    </div>
</div>

<div class="container-fluid footer-separation">

</div>