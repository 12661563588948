<div class="container">
    <div class="row">
        <div class="col">
            <h1>Condiciones de uso</h1>
        </div>
    </div>
    <div class="row">
        <div class="col">		
                <p>En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE), MARIA AGUILAR BOSQUE informa que es titular del sitio web WWW.MABDANCECENTER.COM. De acuerdo con la exigencia del artículo 10 de la citada Ley, MARIA AGUILAR BOSQUE informa de los siguientes datos:</p>
                <p>El titular de este sitio web es MARIA AGUILAR BOSQUE, con DNI 47755465J y domicilio social en CARRER MALALTS 79 08030, BARCELONA. La dirección de correo electrónico de contacto con la empresa es: INFO@MABDANCECENTER.</p>
                <h2>Usuario y régimen de responsabilidades</h2>
                <p>La navegación, acceso y uso por el sitio web de MARIA AGUILAR BOSQUE confiere la condición de usuario, por la que se aceptan, desde la navegación por el sitio web de MARIA AGUILAR BOSQUE, todas las condiciones de uso aquí establecidas sin perjuicio de la aplicación de la correspondiente normativa de obligado cumplimiento legal según el caso.<br>
                El sitio web de MARIA AGUILAR BOSQUE proporciona gran diversidad de información, servicios y datos. El usuario asume su responsabilidad en el uso correcto del sitio web. Esta responsabilidad se extenderá a:</p>
                <p> La veracidad y licitud de las informaciones aportadas por el usuario en los formularios extendidos por MARIA AGUILAR BOSQUE para el acceso a ciertos contenidos o servicios ofrecidos por el web.</p>
                <p> El uso de la información, servicios y datos ofrecidos por MARIA AGUILAR BOSQUE contrariamente a lo dispuesto por las presentes condiciones, la Ley, la moral, las buenas costumbres o el orden público, o que de cualquier otro modo puedan suponer lesión de los derechos de terceros o del mismo funcionamiento del sitio web.</p>
                <h2>Política de enlaces y exenciones de responsabilidad</h2>
                <p>MARIA AGUILAR BOSQUE no se hace responsable del contenido de los sitios web a los que el usuario pueda acceder a través de los enlaces establecidos en su sitio web y declara que en ningún caso procederá a examinar o ejercitar ningún tipo de control sobre el contenido de otros sitios de la red. Asimismo, tampoco garantizará la disponibilidad técnica, exactitud, veracidad, validez o legalidad de sitios ajenos a su propiedad a los que se pueda acceder por medio de los enlaces.</p>
                <p>MARIA AGUILAR BOSQUE declara haber adoptado todas las medidas necesarias para evitar cualquier daño a los usuarios de su sitio web, que pudieran derivarse de la navegación por su sitio web. En consecuencia, MARIA AGUILAR BOSQUE no se hace responsable, en ningún caso, de los eventuales daños que por la navegación por Internet pudiera sufrir el usuario.</p>
                <h2>Modificaciones</h2>
                <p>MARIA AGUILAR BOSQUE se reserva el derecho a realizar las modificaciones que considere oportunas, sin aviso previo, en el contenido de su sitio web. Tanto en lo referente a los contenidos del sitio web, como en las condiciones de uso del mismo. Dichas modificaciones podrán realizarse a través de su sitio web por cualquier forma admisible en derecho y serán de obligado cumplimiento durante el tiempo en que se encuentren publicadas en la web y hasta que no sean modificadas válidamente por otras posteriores.</p>
                <h2>Protección de datos</h2>
                <p>De conformidad con lo establecido en la normativa vigente en Protección de Datos de Carácter Personal, le informamos que sus datos serán incorporados al sistema de tratamiento titularidad de MARIA AGUILAR BOSQUE con CIF 47755465J y domicilio social sito en CARRER MALALTS 79 08030, BARCELONA, con la finalidad de poder facilitar, agilizar y cumplir los compromisos establecidos entre ambas partes. En cumplimiento con la normativa vigente, MARIA AGUILAR BOSQUE informa que los datos serán conservados durante el plazo estrictamente necesario para cumplir con los preceptos mencionados con anterioridad.</p>
                <p>Asimismo le informamos que el tratamiento indicado en el párrafo anterior tratamos sus datos conforme a la existencia de su consentimiento.</p>
                <p>MARIA AGUILAR BOSQUE informa que procederá a tratar los datos de manera lícita, leal, transparente, adecuada, pertinente, limitada, exacta y actualizada. Es por ello que MARIA AGUILAR BOSQUE se compromete a adoptar todas las medidas razonables para que estos se supriman o rectifiquen sin dilación cuando sean inexactos.</p>
                <p>De acuerdo con los derechos que le confiere el la normativa vigente en protección de datos podrá ejercer los derechos de acceso, rectificación, limitación de tratamiento, supresión, portabilidad y oposición al tratamiento de sus datos de carácter personal así como del consentimiento prestado para el tratamiento de los mismos, dirigiendo su petición a la dirección postal indicada más arriba o al correo electrónico INFO@MABDANCECENTER.</p>
                <p>Podrá dirigirse a la Autoridad de Control competente para presentar la reclamación que considere oportuna.</p>
                <h2>Propiedad intelectual e industrial</h2>
                <p>MARIA AGUILAR BOSQUE por sí misma o como cesionaria, es titular de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.), titularidad de MARIA AGUILAR BOSQUE. Serán, por consiguiente, obras protegidas como propiedad intelectual por el ordenamiento jurídico español, siéndoles aplicables tanto la normativa española y comunitaria en este campo, como los tratados internacionales relativos a la materia y suscritos por España.</p>
                <p>Todos los derechos reservados. En virtud de lo dispuesto en la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de MARIA AGUILAR BOSQUE.</p>
                <p>El usuario se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de MARIA AGUILAR BOSQUE. Podrá visualizar los elementos del portal e incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para su uso personal y privado. El usuario deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en las páginas de MARIA AGUILAR BOSQUE.<br>
                Acciones legales, legislación aplicable y jurisdicción</p>
                <p>MARIA AGUILAR BOSQUE se reserva, asimismo, la facultad de presentar las acciones civiles o penales que considere oportunas por la utilización indebida de su sitio web y contenidos, o por el incumplimiento de las presentes condiciones.</p>
                <p>La relación entre el usuario y el prestador se regirá por la normativa vigente y de aplicación en el territorio español. De surgir cualquier controversia las partes podrán someter sus conflictos a arbitraje o acudir a la jurisdicción ordinaria cumpliendo con las normas sobre jurisdicción y competencia al respecto. MARIA AGUILAR BOSQUE tiene su domicilio en BARCELONA, España.</p>
                           
        </div>
    </div>

</div>

<div class="container-fluid footer-separation">

</div>