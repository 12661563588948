import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ReCaptchaService } from 'angular-recaptcha3';

@Component({
  selector: 'app-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent implements OnInit {

  constructor(
    private recaptchaService: ReCaptchaService,
    private _http: HttpClient
    ) { }

  ngOnInit(): void {
  }

  testCaptcha(){
    this.recaptchaService.execute({action: 'forminfo'}).then(token => {
      // Backend verification method
      console.log(token);

      this.finalSend({name:"test",phone:"123123123"},token,'forminfo');
      
    });
  }

  onCaptchaResponse(event: any){
    console.log('onCaptchaResponse');
    
    console.log(event);
    
  }

  finalSend(params:any,token:any,action:any){
    let finalParams:any = {};
    finalParams['token'] = token;
    finalParams['params'] = params;
    finalParams['action'] = action;


    let paramsHttp = new HttpParams();
    paramsHttp = paramsHttp.append('params', JSON.stringify(finalParams));
    let headers = new HttpHeaders();

    
    let resultError = true;
    let resultMessage = 'Ocurrió un error, inténtelo más tarde.';
    this._http.request(
      'post', 'https://contacto.mabdancecenter.com/form_submit_test.php',
      { body: paramsHttp, headers: headers, withCredentials: true })
      .subscribe( (response: any) => {
        console.log(response);
        if(typeof response !== 'undefined'){
          if(typeof response.error !== 'undefined'){
            if(!response.error){
              resultError = false;
              resultMessage = 'Mensaje enviado correctamente.';                      
            } else {
              if(typeof response.error_name !== 'undefined'){
                  switch(response.error_name){
                    
                    case 'empty_params':
                      resultMessage = 'Se deben rellenar todos los campos.';

                    break;
                    case 'not_valid_params':
                    case 'empty_params_system':
                    case 'empty_form_type':
                    case 'not_sent':
                      resultMessage = 'Ocurrió un error, inténtelo más tarde.';

                    break;
                    case 'spam':
                      resultMessage = 'Captcha no correcto.';
                    break;

                  }                      
                }
                
              }
            }
          }
      }, res => {
        console.log(res);
        
      }, () => {
        console.log(resultMessage);
        
      });

  }

}
